import styled from 'styled-components'
import { stylesIf, breakpoints } from '../utils/styleHelpers'
import Button from '@veneer/core/dist/scripts/button'
import Accordion from '@veneer/core/dist/scripts/accordion'
import List from '@veneer/core/dist/scripts/list'

export const StyledList = styled(List)`
  @media screen and (min-height: 700px) and (orientation: portrait) {
    height: 300px;
    overflow: auto;
  }
`

export const ReTargetContainer = styled.div`
  display:flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  box-sizing: border-box;
  min-height: auto
  overflow: hidden;
  width:100%;
  margin: 0 auto;
  height: 100vh;
  justify-content: space-between;
  padding: 2rem 0;
  font-family: "HP Simplified", Arial, sans-serif;

  && {
    .vn-animated-height__wrapper-inner>div {
      padding:0.5rem 0;
    }
  }

  @media screen and (min-width:${breakpoints.tablet}) {
    max-width: 64rem;
    margin: 1rem auto 0;
    padding: 2rem 3.5rem;
    height: 100%;
  }

  @media screen and (min-width:500px) and (max-width:500px) and (min-height:530px ) and (max-height:530px) {
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    box-sizing: border-box;
    min-height: auto
    overflow: hidden;
    width:100%;
    margin-top: 2rem auto;
    height: 100vh;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
  }

  @media only screen and (min-device-width: 768px), (min-device-width: 1024px)
  and (max-device-width: 1024px), (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio: 2) {
    box-sizing: border-box;
    width: 100%;
    margin: 1rem auto 0;
    height: 100vh;
  }
`

export const ReTargetContentContainer = styled.div`
  overflow-y: auto;
  flex-grow: 0;
  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`
export const Title = styled.h4`
  margin-bottom: 1rem;
  margin-top: 0;
  text-align: left;
  color: #313131;
  line-height: 1.3rem;
  font-size: 1.25rem;
  ${(props) =>
    props.fontFlag
      ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
      : `font-family: 'HP Simplified', Arial, sans-serif`};
`

export const Body = styled.div`
  padding: 0;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: #4e4e4e;
  padding-bottom: 1rem;
  ${(props) => (props.od ? `max-width: 50rem;` : `max-width: 34.5rem;`)};

  text-align: left;
  margin: 0 auto;
  ${(props) =>
    props.fontFlag
      ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
      : `font-family: 'HP Simplified', Arial, sans-serif`};

  && {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }

    p,
    > span > p {
      > a {
        color: #027aae;
        :hover {
          color: #006691;
        }
        :active {
          color: #004f74;
        }
      }
    }
  }
`

export const Container = styled.div`
  flex-grow: 0;
  background-color: #f8f8f8;
  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`

export const BodyPostOobe = styled.div`
  &&&& {
    margin-top: 0.625rem;
    margin-bottom: 1rem;
    padding: 0;
    width: 100%;
    font-family: 'HP Simplified', Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    color: #4e4e4e;

    > span {
      > p {
        margin: 1rem 0;
        > a {
          color: #027aae;
          :hover {
            color: #006691;
          }
          :active {
            color: #004f74;
          }
        }
      }
    }
  }
`

export const Footer = styled.span`
  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: left;
    width: 100%;
  }
  @media screen and (max-width: ${breakpoints.tablet}) {
    text-align: center;
  }
  @media only screen and (min-device-width: 768px),
    (min-device-width: 1024px) and (max-device-width: 1024px),
    (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1),
    (-webkit-min-device-pixel-ratio: 2) {
    text-align: ${(props) => (props.isDesktop ? 'left' : 'center')};
  }
  > p {
    > a {
      color: #027aae;
    }
  }
`

export const ToggleLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0 auto;
  ${(props) =>
    props.fontFlag
      ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
      : `font-family: 'HP Simplified', Arial, sans-serif`};
  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 100%;
  }
  @media screen and (min-width: 500px) and (max-width: 500px) and (min-height: 530px) and (max-height: 530px) {
    line-height: 1.25rem;
    font-size: 1rem;
  }

  ${(props) => (props.od ? `max-width: 50rem;` : `max-width: 34.5rem;`)};
`

export const StyledAccordion = styled(Accordion)`
  & > div {
    & > div:first-of-type:focus {
      outline: none;
    }
  }
`

export const Graphic = styled.img`
  width: auto;
`

export const ToggleLabelHeader = styled.label`
  font-size: 1rem;
  line-height: 1.25rem;
  margin-right: 0.5rem;
  font-weight: 600;
`
export const ToggleLabelText = styled.div`
  display: inline;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  line-height: 1.125rem;
`

export const StyledButton = styled(Button)`
  @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
    margin-left: 50%;
    transform: translate(-50%);
  }
  min-height: ${stylesIf('small', '2.25', '2.875')}rem;
  ${stylesIf('small', 'border-radius: 0.5rem;')}
  ${stylesIf(
    'hasBody',
    `
        @media screen and (min-width: 25.875rem) {
          margin-top: 1rem;
        }
      `
  )}
  @media screen and (min-width:500px) and (max-width:500px) and (min-height:530px ) and (max-height:530px) {
    padding-right: 1rem;
  }

  @media only screen and (min-device-width: 768px),
    (min-device-width: 1024px) and (max-device-width: 1024px),
    (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1),
    (-webkit-min-device-pixel-ratio: 2) {
    margin-top: 1rem;
  }
`

export const ConsentsButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 10px;
  > button {
    ${(props) =>
      props.fontFlag
        ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
        : `font-family: 'HP Simplified', Arial, sans-serif`};
  }
  & > button:first-of-type {
    margin-left: 0;
    margin-left: 0.625rem;
  }

  // less than or equal to 414 pixels
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    & > button:first-of-type {
      margin-left: 0;
      margin-bottom: 0;
      width: 100%;
    }
    & > button:nth-of-type(2) {
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
      width: 100%;
    }
  }
`

export const FooterButton = styled(Button)`
  width: fit-content;
`
