import React from 'react'
import { usePrinterSettingsHook } from './hooks'
import PrinterConsents from './PrinterConsents'

const PrinterContainer = ({ navigation, sessionContext, nextStage, language, country }) => {
  const printerSettingsProps = usePrinterSettingsHook(
    navigation,
    sessionContext,
    nextStage,
    language,
    country
  )
  return <PrinterConsents {...printerSettingsProps} />
}

export default PrinterContainer
