import React from 'react'
import {
  Container,
  BodyContainer,
  StyledButton,
  Title,
  ButtonContainer
} from '../styles/adminStyles'
import { useLocation, useHistory } from 'react-router'

const FeatureFlagLoader = (props) => {
  const { navigation, nextStage, sessionContext } = props
  const { search } = useLocation()
  const history = useHistory()

  const queryParams = new URLSearchParams(search)
  const redirectUri = queryParams.get('redirectUri')
  const redirect = navigation || history

  const handleClick = () => {
    if (sessionContext) {
      nextStage({ result: 'success' })
    } else {
      redirect.push(redirectUri)
    }
  }

  return (
    <Container>
      <BodyContainer id="content-container">
        <Title id="title-text">
          This feature or page is not enabled for this environment. Coming Soon!!!
        </Title>
        <ButtonContainer>
          <StyledButton onClick={handleClick} appearance={'primary'} id="continue-button">
            Continue
          </StyledButton>
        </ButtonContainer>
      </BodyContainer>
    </Container>
  )
}

export default FeatureFlagLoader
