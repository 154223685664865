import React, { useState } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { StyledButton, Body, FooterButton, ButtonContainer } from '../../styles/adminStyles'
import { ConsentsButtonContainer } from '../../styles/adminPrivacySettingsStyles'
import ODPrivacySettings from './ODPrivacySettings'
import { Container, BodyContainer, Title, MainBody, BodyList, Graphic } from '../../styles/odStyles'

const WFHComponent = (props) => {
  const {
    handleConsentContinueClick,
    alpacaData = [],
    craftData = {},
    modalCraftData = {},
    country,
    loading,
    acceptAllButtonLoading,
    declineAllButtonLoading,
    continueButtonLoading,
    isAlreadyConsented,
    isAnalyticsOn
  } = props
  const [show, setShow] = useState(false)
  const { ffSmbVeneerFonts } = useFlags()

  console.log(
    `Onboarding WFH Center Component > alpacaData: ${alpacaData}, craftData: ${craftData}`
  )

  if (alpacaData && craftData) {
    const {
      entry: {
        smart_suite_image,
        smart_suite_heading_text,
        smart_suite_body,
        accept_button_text,
        decline_button_text,
        manage_button_text,
        continue_button_text
      }
    } = craftData
    const smbAdminBodyText1 = smart_suite_body[0].body.body_text
    const smbAdminBodyText2 = smart_suite_body[0].body.secondary_body_text
    const smbAdminBodyText3 = smart_suite_body[0].body.supplimental_text

    const isRestOfWorld = country.toUpperCase() !== 'US' ? true : false

    const getWelcomeContainer = () => {
      return (
        <Container>
          {!show && (
            <BodyContainer id="content-container">
              <Graphic
                id="graphic"
                src={smart_suite_image?.url}
                alt="Printer and tablet line art"
              />
              <Title id="title-text" fontFlag={ffSmbVeneerFonts}>
                {smart_suite_heading_text}
              </Title>
              <MainBody id="hpsmart-content" fontFlag={ffSmbVeneerFonts}>
                <div>
                  <span dangerouslySetInnerHTML={{ __html: smbAdminBodyText1 }} />
                  {country === 'us' ? (
                    <BodyList dangerouslySetInnerHTML={{ __html: smbAdminBodyText2 }} />
                  ) : (
                    <BodyList dangerouslySetInnerHTML={{ __html: smbAdminBodyText3 }} />
                  )}
                </div>
              </MainBody>

              <ButtonContainer fontFlag={ffSmbVeneerFonts}>
                {alpacaData && (
                  <StyledButton
                    onClick={handleConsentContinueClick}
                    loading={continueButtonLoading}
                    appearance="primary"
                    id="consent-button"
                  >
                    {continue_button_text}
                  </StyledButton>
                )}
              </ButtonContainer>
            </BodyContainer>
          )}
        </Container>
      )
    }

    return getWelcomeContainer()
  }

  return <ProgressIndicator appearance="linear" />
}

export default WFHComponent
