import React, { useState } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  Container,
  BodyContainer,
  StyledButton,
  Title,
  Body,
  MainBody,
  FooterButton,
  ButtonContainer
} from '../styles/adminStyles'
import { ConsentsButtonContainer } from '../styles/adminPrivacySettingsStyles'
import AdminPrivacySettings from './AdminPrivacySettings'
import {
  publishEvent,
  ManageConsentButtonClicked,
  BackButtonClicked,
  ManageConsentScreenDisplayed,
  ConsentsScreenDisplayedNewDomain
} from '../utils/analytics'

const AdminConsents = (props) => {
  const {
    handleClick,
    handleContinueClick,
    declineAllClick,
    consentedContinueClick,
    alpacaData = [],
    craftData = {},
    modalCraftData = {},
    country,
    loading,
    acceptAllButtonLoading,
    declineAllButtonLoading,
    continueButtonLoading,
    isAlreadyConsented,
    isAnalyticsOn
  } = props
  const [show, setShow] = useState(false)
  const { ffSmbVeneerFonts } = useFlags()

  if (alpacaData && craftData && modalCraftData) {
    const {
      entry: {
        smart_suite_heading_text,
        smart_suite_body,
        accept_button_text,
        decline_button_text,
        manage_button_text,
        continue_button_text
      }
    } = craftData
    const smbAdminBodyText1 = smart_suite_body[0]?.body?.body_text
    const smbAdminBodyText2 = smart_suite_body[0]?.body?.secondary_body_text
    const smbAdminBodyText3 = smart_suite_body[0]?.body?.supplimental_text

    const isRestOfWorld = country.toUpperCase() !== 'US' ? true : false
    // if the admin account is already consented, then we want to display different text and content for buttons
    const optional_button_text = isAlreadyConsented ? continue_button_text : accept_button_text

    const getWelcomeContainer = () => {
      return (
        <Container>
          <BodyContainer id="content-container">
            <Title id="title-text" fontFlag={ffSmbVeneerFonts}>
              {smart_suite_heading_text}
            </Title>
            <MainBody id="hpsmart-content" fontFlag={ffSmbVeneerFonts}>
              <span dangerouslySetInnerHTML={{ __html: smbAdminBodyText1 }} />
            </MainBody>
            <div>
              {alpacaData && !isAlreadyConsented && (
                <Body id="hpsmart-content" fontFlag={ffSmbVeneerFonts}>
                  <span dangerouslySetInnerHTML={{ __html: smbAdminBodyText2 }} />
                </Body>
              )}
              {alpacaData && isAlreadyConsented && isAnalyticsOn && (
                <Body id="hpsmart-content" fontFlag={ffSmbVeneerFonts}>
                  <span dangerouslySetInnerHTML={{ __html: smbAdminBodyText3 }} />
                </Body>
              )}
            </div>
            <ButtonContainer fontFlag={ffSmbVeneerFonts}>
              {alpacaData && (
                <StyledButton
                  onClick={isAlreadyConsented ? consentedContinueClick : handleClick}
                  loading={isAlreadyConsented ? loading : acceptAllButtonLoading}
                  appearance="primary"
                  id="consent-button"
                >
                  {optional_button_text}
                </StyledButton>
              )}
              {!isAlreadyConsented && (
                <StyledButton
                  onClick={declineAllClick}
                  loading={declineAllButtonLoading}
                  appearance="primary"
                  id="consent-decline-all-button"
                >
                  {decline_button_text}
                </StyledButton>
              )}
              {alpacaData && !isAlreadyConsented && (
                <StyledButton
                  onClick={() => {
                    setShow(true)
                    publishEvent(ManageConsentButtonClicked)
                    publishEvent(ManageConsentScreenDisplayed)
                  }}
                  appearance="secondary"
                  id="consent-manage-options-button"
                >
                  {manage_button_text}
                </StyledButton>
              )}
            </ButtonContainer>
            {show && (
              <Modal
                id="regular-modal"
                onClose={() => setShow(false)}
                show={show}
                footer={
                  <ConsentsButtonContainer
                    id="consents-button-container"
                    fontFlag={ffSmbVeneerFonts}
                  >
                    <FooterButton
                      onClick={handleContinueClick}
                      id="smb-consent-continue-button"
                      loading={continueButtonLoading}
                    >
                      {modalCraftData?.entry?.continue_button_text}
                    </FooterButton>
                    <FooterButton
                      onClick={() => {
                        setShow(false)
                        publishEvent(BackButtonClicked)
                        publishEvent(ConsentsScreenDisplayedNewDomain)
                      }}
                      appearance="secondary"
                      id="smb-consent-back-button"
                    >
                      {modalCraftData?.entry?.back_button_text}
                    </FooterButton>
                  </ConsentsButtonContainer>
                }
              >
                <AdminPrivacySettings {...props} />
              </Modal>
            )}
          </BodyContainer>
        </Container>
      )
    }

    return getWelcomeContainer()
  }

  return <ProgressIndicator appearance="linear" />
}

export default AdminConsents
