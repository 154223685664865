import { prop, head, includes, compose } from 'ramda'
import { SUPPORTED_LANGUAGES } from '../constants/i18n'

export const getBrowserLocale = () => {
  const {
    language,
    browserLanguage,
    systemLanguage,
    userLanguage,
    languages = []
  } = window.navigator
  return language || browserLanguage || systemLanguage || userLanguage || head(languages)
}

export const splitLocaleIntoCountryAndLanguage = (locale = '') => ({
  language: locale.slice(0, 2),
  country: locale.slice(-2, locale.length).toLowerCase()
})

export const getLocale = () => {
  const browserLang = getBrowserLocale()
  const { country, language } = splitLocaleIntoCountryAndLanguage(browserLang)

  return { country, language }
}

export function determineLocale() {
  const { country, language } = getLocale()

  if (prop(language, SUPPORTED_LANGUAGES)) {
    return compose(includes(country), prop(language))(SUPPORTED_LANGUAGES)
      ? { language, country }
      : {
          language,
          country: compose(head, prop(language))(SUPPORTED_LANGUAGES)
        }
  }

  return {
    language: 'en',
    country: 'us'
  }
}
