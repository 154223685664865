import React, { useState } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  Container,
  BodyContainer,
  StyledButton,
  Title,
  Body,
  BodyList,
  FooterButton,
  ButtonContainer
} from '../styles/adminStyles'
import { ConsentsButtonContainer } from '../styles/adminPrivacySettingsStyles'
import PrinterPrivacySettings from './PrinterPrivacySettings'
import {
  publishEvent,
  ManageConsentButtonClicked,
  ManageConsentScreenDisplayed,
  BackButtonClicked,
  ConsentsScreenDisplayedNewDomain
} from '../utils/analytics'

const PrinterConsents = (props) => {
  const {
    handleClick,
    handleContinueClick,
    declineAllClick,
    alpacaData = [],
    craftData = {},
    modalCraftData = {},
    country,
    acceptAllButtonLoading,
    declineAllButtonLoading,
    continueButtonLoading
  } = props
  const [show, setShow] = useState(false)
  const { ffSmbVeneerFonts } = useFlags()

  if (alpacaData && craftData && modalCraftData) {
    const {
      entry: {
        smart_suite_heading_text,
        smart_suite_body,
        accept_button_text,
        decline_button_text,
        manage_button_text
      }
    } = craftData
    const smbAdminBodyText1 = smart_suite_body?.[0]?.body?.body_text
    const secondaryBodyText = smart_suite_body?.[0]?.body?.secondary_body_text
    const supplementalText = smart_suite_body?.[0]?.body?.supplimental_text
    const isRestOfWorld = country.toUpperCase() !== 'US' ? true : false

    const getWelcomeContainer = () => {
      return (
        <Container>
          <BodyContainer id="content-container">
            <Title id="title-text" fontFlag={ffSmbVeneerFonts}>
              {smart_suite_heading_text}
            </Title>
            <Body id="hpsmart-content" fontFlag={ffSmbVeneerFonts}>
              <span dangerouslySetInnerHTML={{ __html: smbAdminBodyText1 }} />
              {isRestOfWorld ? (
                <BodyList dangerouslySetInnerHTML={{ __html: supplementalText }} />
              ) : (
                <BodyList dangerouslySetInnerHTML={{ __html: secondaryBodyText }} />
              )}
            </Body>
            {smart_suite_body && (
              <ButtonContainer fontFlag={ffSmbVeneerFonts}>
                <StyledButton
                  onClick={handleClick}
                  appearance="primary"
                  id="consent-button"
                  loading={acceptAllButtonLoading}
                >
                  {accept_button_text}
                </StyledButton>
                <StyledButton
                  onClick={declineAllClick}
                  appearance="primary"
                  id="consent-decline-all-button"
                  loading={declineAllButtonLoading}
                >
                  {decline_button_text}
                </StyledButton>
                <StyledButton
                  onClick={() => {
                    setShow(true)
                    publishEvent(ManageConsentButtonClicked)
                    publishEvent(ManageConsentScreenDisplayed)
                  }}
                  appearance="secondary"
                  id="consent-manage-options-button"
                >
                  {manage_button_text}
                </StyledButton>
              </ButtonContainer>
            )}
            {show && (
              <Modal
                id="regular-modal"
                onClose={() => setShow(false)}
                show={show}
                footer={
                  <ConsentsButtonContainer
                    id="consents-button-container"
                    fontFlag={ffSmbVeneerFonts}
                  >
                    <FooterButton
                      onClick={handleContinueClick}
                      id="smb-consent-continue-button"
                      loading={continueButtonLoading}
                    >
                      {modalCraftData?.entry?.continue_button_text}
                    </FooterButton>
                    <FooterButton
                      onClick={() => {
                        setShow(false)
                        publishEvent(BackButtonClicked)
                        publishEvent(ConsentsScreenDisplayedNewDomain)
                      }}
                      appearance="secondary"
                      id="smb-consent-back-button"
                    >
                      {modalCraftData?.entry?.back_button_text}
                    </FooterButton>
                  </ConsentsButtonContainer>
                }
              >
                <PrinterPrivacySettings {...props} />
              </Modal>
            )}
          </BodyContainer>
        </Container>
      )
    }
    return getWelcomeContainer()
  }

  return <ProgressIndicator appearance="linear" />
}

export default PrinterConsents
