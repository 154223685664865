import React from 'react'
import { useAdminSettingsHook } from './hooks'
import AdminConsents from './AdminConsents'

const AdminContainer = ({
  authProvider,
  navigation,
  sessionContext,
  nextStage,
  language,
  country
}) => {
  const adminSettingsProps = useAdminSettingsHook(
    authProvider,
    navigation,
    sessionContext,
    nextStage,
    language,
    country
  )
  return <AdminConsents {...adminSettingsProps} />
}

export default AdminContainer
