import styled from 'styled-components'
import Button from '@veneer/core/dist/scripts/button'

export const BodyContainer = styled.div`
  align-items: left;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #f8f8f8;
  min-height: 100%;
`

export const Container = styled.div`
  overflow-y: auto;
`

export const BodyList = styled.span`
  > ul {
    list-style: disc;
    text-align: left;
    margin-left: 2rem;
  }
`

export const Body = styled.div`
  &&&& {
    padding: 0;
    width: 100%;
    ${(props) =>
      props.fontFlag
        ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
        : `font-family: 'HP Simplified', Arial, sans-serif`};
    font-size: 1rem;
    line-height: 1.5;
    color: #4e4e4e;

    > span {
      > p {
        margin: 1rem 0;
        > a {
          font-weight: bold;
          text-decoration: underline;
          color: #027aae;
          :hover {
            color: #006691;
          }
          :active {
            color: #004f74;
          }
        }
      }
    }
  }
`

export const MainBody = styled.div`
  &&&& {
    padding: 0;
    width: 100%;
    ${(props) =>
      props.fontFlag
        ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
        : `font-family: 'HP Simplified', Arial, sans-serif`};
    font-size: 1rem;
    line-height: 1.5;
    color: #4e4e4e;
    margin-top: 1rem;
  }
`

export const Title = styled.div`
  && {
    color: #313131;
    line-height: 1.3;
    font-size: 2.5rem;
    padding-top: 1rem;
    ${(props) =>
      props.fontFlag
        ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
        : `font-family: 'HP Simplified', Arial, sans-serif`};
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  justify-content: end;
  margin-top: 1rem;

  > button {
    margin-right: 1rem;
    font-family: ${(props) =>
      props.fontFlag
        ? `'Forma DJR Micro', Arial, sans-serif`
        : `'HP Simplified', Arial, sans-serif`};
  }

  // less than or equal to 414 pixels
  @media screen and (max-width: 767px) {
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > button:nth-of-type(2) {
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
    }
  }
`

export const StyledButton = styled(Button)`
  display: block;
  width: auto;
`

export const FooterButton = styled(Button)`
  width: fit-content;
`
