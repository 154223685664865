import React from 'react'

import {
  Container,
  ToggleLabelText,
  ToggleLabelHeader,
  BodyPostOobe,
  StyledList
} from '../styles/adminPrivacySettingsStyles'
import Toggle from '@veneer/core/dist/scripts/toggle'
import ListItem from '@veneer/core/dist/scripts/list_item'
import IconJobs from '@veneer/core/dist/scripts/icons/icon_jobs'
import IconBarChart from '@veneer/core/dist/scripts/icons/icon_bar_chart'
import IconMegaphone from '@veneer/core/dist/scripts/icons/icon_megaphone'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { MainDiv } from '../styles/appStyles'
import { smbAdvertizing, smbAnalytics } from '../constants/purposeID'
import { BodyContainer } from '../styles/adminStyles'

const OobePreferences = (props) => {
  const {
    requiredPrivacy = false,
    analytics = false,
    advertising = false,
    setAnalytics,
    setAdvertising,
    appAnalyticsContent = '',
    advertisingContent = '',
    defaultConsentContent = '',
    modalCraftData,
    handleClick
  } = props

  if (modalCraftData) {
    const {
      entry: { smart_suite_body, smart_suite_consent_header_text }
    } = modalCraftData
    const smbAdminPrivacySubHeadingText = smart_suite_body[0]?.body?.body_text

    const requiredBasicSmbTitle =
      smart_suite_consent_header_text[0]?.consent_headers?.consent_header_text
    const appAnalyticsTitle =
      smart_suite_consent_header_text[1]?.consent_headers?.consent_header_text
    const advertisingTitle =
      smart_suite_consent_header_text[2]?.consent_headers?.consent_header_text

    const PrivacyConsents = () => {
      return (
        <StyledList>
          <ListItem
            leadingArea={<IconJobs />}
            centerArea={<ToggleLabelHeader>{requiredBasicSmbTitle}</ToggleLabelHeader>}
            trailingArea={<Toggle id="required-toggle" defaultOn={requiredPrivacy} disabled />}
          />
          <ListItem
            leadingArea={
              <ToggleLabelText dangerouslySetInnerHTML={{ __html: defaultConsentContent }} />
            }
          />
          <ListItem
            leadingArea={<IconBarChart />}
            centerArea={<ToggleLabelHeader>{appAnalyticsTitle}</ToggleLabelHeader>}
            trailingArea={
              <Toggle
                id="app-analytics-toggle"
                defaultOn={analytics}
                onChange={(e) => {
                  setAnalytics(e)
                  handleClick(e, smbAnalytics)
                }}
              />
            }
          />
          <ListItem
            leadingArea={
              <ToggleLabelText dangerouslySetInnerHTML={{ __html: appAnalyticsContent }} />
            }
          />
          <ListItem
            leadingArea={<IconMegaphone />}
            centerArea={<ToggleLabelHeader>{advertisingTitle}</ToggleLabelHeader>}
            trailingArea={
              <Toggle
                id="advertising-toggle"
                defaultOn={advertising}
                onChange={(e) => {
                  setAdvertising(e)
                  handleClick(e, smbAdvertizing)
                }}
              />
            }
          />
          <ListItem
            leadingArea={
              <ToggleLabelText dangerouslySetInnerHTML={{ __html: advertisingContent }} />
            }
          />
        </StyledList>
      )
    }

    return (
      <Container id="content-container">
        <BodyContainer id="settings-container-body">
          <BodyPostOobe
            id="settings-container-body"
            dangerouslySetInnerHTML={{ __html: smbAdminPrivacySubHeadingText }}
          />
          <PrivacyConsents />
        </BodyContainer>
      </Container>
    )
  }

  return (
    <MainDiv>
      <ProgressIndicator />
    </MainDiv>
  )
}

export default OobePreferences
