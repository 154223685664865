const AE = 'ae'
const AF = 'af'
const AG = 'ag'
const AI = 'ai'
const AL = 'al'
const AO = 'ao'
const AR = 'ar'
const AT = 'at'
const AU = 'au'
const AW = 'aw'
const AZ = 'az'
const BA = 'ba'
const BB = 'bb'
const BD = 'bd'
const BE = 'be'
const BG = 'bg'
const BH = 'bh'
const BJ = 'bj'
const BL = 'bl'
const BN = 'bn'
const BO = 'bo'
const BQ = 'bq'
const BR = 'br'
const BS = 'bs'
const BT = 'bt'
const BW = 'bw'
const BY = 'by'
const BZ = 'bz'
const CA = 'ca'
const CH = 'ch'
const CI = 'ci'
const CL = 'cl'
const CM = 'cm'
const CN = 'cn'
const CO = 'co'
const CR = 'cr'
const CS = 'cs'
const CU = 'cu'
const CW = 'cw'
const CY = 'cy'
const CZ = 'cz'
const DA = 'da'
const DE = 'de'
const DK = 'dk'
const DO = 'do'
const DZ = 'dz'
const EC = 'ec'
const EE = 'ee'
const EG = 'eg'
const EL = 'el'
const EN = 'en'
const ES = 'es'
const ET = 'et'
const FI = 'fi'
const FR = 'fr'
const GB = 'gb'
const GD = 'gd'
const GE = 'ge'
const GF = 'gf'
const GH = 'gh'
const GP = 'gp'
const GR = 'gr'
const GT = 'gt'
const HE = 'he'
const HK = 'hk'
const HR = 'hr'
const HN = 'hn'
const HT = 'ht'
const HU = 'hu'
const ID = 'id'
const IE = 'ie'
const IL = 'il'
const IN = 'in'
const IS = 'is'
const IT = 'it'
const JA = 'ja'
const JM = 'jm'
const JO = 'jo'
const JP = 'jp'
const KE = 'ke'
const KH = 'kh'
const KN = 'kn'
const KO = 'ko'
const KR = 'kr'
const KW = 'kw'
const KY = 'ky'
const KZ = 'kz'
const LA = 'la'
const LB = 'lb'
const LC = 'lc'
const LK = 'lk'
const LT = 'lt'
const LI = 'li'
const LU = 'lu'
const LV = 'lv'
const LY = 'ly'
const MA = 'ma'
const MD = 'md'
const ME = 'me'
const MF = 'mf'
const MG = 'mg'
const MK = 'mk'
const MM = 'mm'
const MN = 'mn'
const MQ = 'mq'
const MS = 'ms'
const MT = 'mt'
const MU = 'mu'
const MV = 'mv'
const MX = 'mx'
const MY = 'my'
const MZ = 'mz'
const NA = 'na'
const NB = 'nb'
const NC = 'nc'
const NG = 'ng'
const NI = 'ni'
const NL = 'nl'
const NO = 'no'
const NP = 'np'
const NZ = 'nz'
const OM = 'om'
const PA = 'pa'
const PE = 'pe'
const PF = 'pf'
const PH = 'ph'
const PK = 'pk'
const PL = 'pl'
const PR = 'pr'
const PT = 'pt'
const PY = 'py'
const QA = 'qa'
const RE = 're'
const RO = 'ro'
const RS = 'rs'
const RU = 'ru'
const RW = 'rw'
const SA = 'sa'
const SE = 'se'
const SG = 'sg'
const SI = 'si'
const SK = 'sk'
const SL = 'sl'
const SN = 'sn'
const SV = 'sv'
const SX = 'sx'
const TC = 'tc'
const TH = 'th'
const TL = 'tl'
const TN = 'tn'
const TR = 'tr'
const TT = 'tt'
const TW = 'tw'
const TZ = 'tz'
const UA = 'ua'
const UG = 'ug'
const US = 'us'
const UY = 'uy'
const UZ = 'uz'
const VC = 'vc'
const VE = 've'
const VG = 'vg'
const VI = 'vi'
const VN = 'vn'
const YT = 'yt'
const ZA = 'za'
const ZM = 'zm'
const ZH = 'zh'

export const DEFAULT_COUNTRY = 'US'
export const DEFAULT_LANGUAGE = 'en'

export const LANGUAGES_ARRAY = [
  AR,
  BG,
  CA,
  CS,
  DA,
  DE,
  EL,
  EN,
  ES,
  ET,
  FI,
  FR,
  HE,
  HR,
  HU,
  ID,
  JA,
  KO,
  LT,
  LV,
  IT,
  // MT,
  NB,
  NL,
  NO,
  PL,
  PT,
  RO,
  RU,
  SI,
  SK,
  SL,
  SV,
  TH,
  TR,
  ZH
]
export const LANGUAGES_SET = new Set(LANGUAGES_ARRAY)

export const LANGUAGES = {
  AR,
  BG,
  CA,
  CS,
  DA,
  DE,
  EL,
  EN,
  ES,
  ET,
  FI,
  FR,
  HE,
  HR,
  HU,
  ID,
  JA,
  KO,
  LT,
  LV,
  IT,
  // MT,
  NB,
  NL,
  NO,
  PL,
  PT,
  RO,
  RU,
  SI,
  SK,
  SL,
  SV,
  TH,
  TR,
  ZH
}

export const COUNTRIES_ARRAY = [
  AE,
  AF,
  AG,
  AI,
  AL,
  AO,
  AR,
  AT,
  AU,
  AW,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BG,
  BH,
  BJ,
  BL,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BW,
  BY,
  BZ,
  CA,
  CH,
  CI,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CW,
  CY,
  CZ,
  DE,
  DK,
  DO,
  DZ,
  EC,
  EE,
  EG,
  ES,
  ET,
  FI,
  FR,
  GB,
  GD,
  GE,
  GF,
  GH,
  GP,
  GR,
  GT,
  HK,
  HN,
  HR,
  HT,
  HU,
  UA,
  UZ,
  ID,
  IE,
  IL,
  IN,
  IS,
  IT,
  JM,
  JO,
  JP,
  KE,
  KH,
  KN,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LT,
  LU,
  LV,
  LY,
  MA,
  MD,
  ME,
  MF,
  MG,
  MK,
  MM,
  MQ,
  MS,
  MT,
  MU,
  MV,
  MX,
  MY,
  MZ,
  NA,
  NA,
  NC,
  NI,
  NL,
  NO,
  NP,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PH,
  PK,
  PL,
  PR,
  PT,
  PY,
  QA,
  RE,
  RO,
  RS,
  RW,
  SA,
  SE,
  SG,
  SI,
  SK,
  SN,
  SV,
  SX,
  TC,
  TH,
  TL,
  TN,
  TR,
  TT,
  TW,
  TZ,
  US,
  UY,
  VC,
  VE,
  VG,
  VI,
  VN,
  YT,
  ZA,
  ZM
]

export const COUNTRIES_SET = new Set(COUNTRIES_ARRAY)

export const COUNTRIES = {
  AE,
  AF,
  AG,
  AI,
  AL,
  AO,
  AR,
  AT,
  AU,
  AW,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BG,
  BH,
  BJ,
  BL,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BW,
  BY,
  BZ,
  CA,
  CH,
  CI,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CW,
  CY,
  CZ,
  DE,
  DK,
  DO,
  DZ,
  EC,
  EE,
  EG,
  ES,
  ET,
  FI,
  FR,
  GB,
  GD,
  GE,
  GF,
  GH,
  GP,
  GR,
  GT,
  HK,
  HN,
  HR,
  HT,
  HU,
  UA,
  UZ,
  ID,
  IE,
  IL,
  IN,
  IS,
  IT,
  JM,
  JO,
  JP,
  KE,
  KH,
  KN,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LT,
  LU,
  LV,
  LY,
  MA,
  MD,
  ME,
  MF,
  MG,
  MK,
  MM,
  MQ,
  MS,
  MT,
  MU,
  MV,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NI,
  NL,
  NO,
  NP,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PH,
  PK,
  PL,
  PR,
  PT,
  PY,
  QA,
  RE,
  RO,
  RS,
  RW,
  SA,
  SE,
  SG,
  SI,
  SK,
  SN,
  SV,
  SX,
  TC,
  TH,
  TL,
  TN,
  TR,
  TT,
  TW,
  TZ,
  US,
  UY,
  VC,
  VE,
  VG,
  VI,
  VN,
  YT,
  ZA,
  ZM
}
export const SIMPLIFIED_CHINESE_COUNTRIES = new Set([CN, SG])

export const SUPPORTED_LANGUAGES = {
  [AR]: [SA, AE, BH, EG, JO, KW, LB, LY, MA, OM, QA, TN],
  [BG]: [BG],
  [CA]: [ES],
  [CS]: [CZ],
  [DA]: [DK],
  [DE]: [DE, AT, CH, LI, LU],
  [EL]: [GR, CY],
  [EN]: [
    US,
    CM,
    AE,
    AF,
    AG,
    AI,
    AL,
    AU,
    AW,
    AZ,
    BA,
    BB,
    BD,
    BH,
    BN,
    BS,
    BT,
    BW,
    BZ,
    CA,
    CR,
    CY,
    DZ,
    EG,
    ET,
    GB,
    GD,
    GE,
    GH,
    HK,
    ID,
    IE,
    IL,
    IN,
    IS,
    JM,
    JO,
    JP,
    KE,
    KH,
    KN,
    KY,
    KW,
    LA,
    LB,
    LC,
    LK,
    LY,
    ME,
    MK,
    MM,
    MN,
    MS,
    MT,
    MU,
    MY,
    MV,
    NA,
    NG,
    NP,
    NZ,
    OM,
    PH,
    PK,
    PR,
    QA,
    RS,
    RW,
    SA,
    SG,
    SX,
    TC,
    TH,
    TL,
    TN,
    TT,
    TZ,
    UG,
    VC,
    VG,
    VI,
    VN,
    ZA,
    ZM
  ],
  [ES]: [ES, AR, BO, CL, CO, CU, DO, EC, GT, HN, MX, NI, PE, PR, PA, PY, SV, UY, VE],
  [ET]: [EE],
  [FI]: [FI, SV],
  [FR]: [FR, BE, BJ, BL, CA, CH, GF, GP, HT, LC, LU, MF, MG, MQ, NC, PF, RE, SN, YT],
  [HE]: [IL],
  [HU]: [HU, UA, UZ],
  [HR]: [HR],
  [ID]: [ID],
  [IT]: [IT, CH],
  [JA]: [JP],
  [KO]: [KR],
  [LT]: [LT],
  [LV]: [LV],
  // [MT]: [MT],
  [NL]: [BE, BQ, CW, FR, NL],
  [NB]: [NO],
  [PL]: [PL],
  [PT]: [BR, PT, AO, MZ],
  [RO]: [RO, MD],
  [RU]: [RU, BY, KZ],
  [SK]: [SK],
  [SL]: [SI],
  [SV]: [SE, FI],
  [TH]: [TH],
  [TR]: [TR],
  [HU]: [HU],
  [ZH]: [CN, HK, SG, TW]
}
