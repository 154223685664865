/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import PortalConsents from '../src/components/PortalConsents'
import { ShellProps } from './types/shell'
import { Stack } from '@jarvis/web-stratus-client'
import pkg from '../package.json'
import axios from 'axios'
declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Root({ stack = Stack.pie, properties, type }) {
  const {
    v1: { authProvider, navigation, store, localization }
  } = window.Shell as ShellProps

  // You can find all Shell´s props here. See "./types/shell" for more details.
  // const { v1 } = window.Shell as ShellProps
  // getAccessToken()
  const { sessionContext = '', nextStage = '' } = store.state.onboarding || {}

  // smb.dev.portalshell.int.hp.com
  // smb.pie.portalshell.int.hp.com
  // smb.stage.portalshell.int.hp.com
  // admin.hpsmart.com

  const getStack = () => {
    return stack !== Stack.prod ? Stack[stack] : ''
  }

  console.log(
    `Portal Consents- SessionContext: ${sessionContext}, nextStage: ${nextStage}, properties: ${properties}, type: ${type}, stack: ${stack}`
  )

  const defaultUrl = properties
    ? `https://${properties.consentsApi || properties?.privacy?.consentsApi}`
    : `https://api.hpsmart${getStack()}.com`
  axios.defaults.baseURL = defaultUrl

  const tenantType = sessionContext?.tenant?.type || ''
  const clientId = properties.ldClientId || properties?.privacy?.ldClientId
  return (
    <section id={pkg.name}>
      <PortalConsents
        authProvider={authProvider}
        localization={localization}
        navigation={navigation}
        nextStage={nextStage}
        sessionContext={sessionContext}
        store={store}
        stack={stack}
        tenantType={tenantType}
        type={type}
        ldClientId={clientId}
      />
    </section>
  )
}
