import React, { useEffect } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { theme } from './utils/styleHelpers'
import AdminContainer from './SmartWelcome/AdminContainer'
import PrinterContainer from './SmartWelcome/PrinterContainer'
import GOContainer from './SmartWelcome/GO/GOContainer'
import PrinterConsentsCreate from './SmartWelcome/PrinterConsentsCreate'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { MainDiv } from './styles/appStyles'
import { useFlags } from 'launchdarkly-react-client-sdk'
import ECPContainer from './SmartWelcome/ECP/ECPContainer'
import FeatureFlagLoader from './SmartWelcome/FeatureFlagLoader'
import ODContainer from './SmartWelcome/OD/ODContainer'
import WFHContainer from './SmartWelcome/OD/WFHContainer'

const HTTP_STATUS_CODE = 200

const App = (props) => {
  // This console just for debug purpose, it will be removed before PROD deployment
  console.log('Portal Consents App Props:- ', props)
  const { language = 'en', country = 'US' } = props.localization
  const {
    derivedAttributes: {
      ucdeProgramMgtSvc: {
        participant: { httpStatusCode }
      }
    },
    onboardingContext: { productFamily }
  } = props.sessionContext
  useEffect(() => {
    const {
      state: {
        onboarding: { setSidebarVisibility }
      }
    } = props.store
    setSidebarVisibility(true)
  }, [props.sessionContext])
  const { pdcnBusinessFlowEnabled, ffWfhOd } = useFlags()
  if (!props.tenantType && props.navigation.location.pathname !== '/consumer-printer-consents') {
    console.log('Portal Consents >> Error: Missing Tenant Type')
    return (
      <MainDiv>
        <h3>Tenant Type Missing</h3>
        <ProgressIndicator />
      </MainDiv>
    )
  }
  return (
    <Router history={props.navigation}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/smb-printer-consents">
            {props.tenantType === 'Personal' ? (
              <PrinterContainer
                navigation={props.navigation}
                sessionContext={props.sessionContext}
                nextStage={props.nextStage}
                language={language}
                country={country}
              />
            ) : productFamily === 'LFP_DUNE' ? ( // LFP_DUNE for '/go' flow
              <GOContainer
                accessToken={props.accessToken}
                authProvider={props.authProvider}
                country={country}
                language={language}
                navigation={props.navigation}
                nextStage={props.nextStage}
                sessionContext={props.sessionContext}
              />
            ) : pdcnBusinessFlowEnabled ? (
              <AdminContainer
                accessToken={props.accessToken}
                authProvider={props.authProvider}
                country={country}
                language={language}
                navigation={props.navigation}
                nextStage={props.nextStage}
                sessionContext={props.sessionContext}
              />
            ) : (
              <FeatureFlagLoader
                navigation={props.navigation}
                nextStage={props.nextStage}
                sessionContext={props.sessionContext}
              />
            )}
          </Route>
          <Route path="/create-smb-personal-consents">
            <PrinterConsentsCreate
              navigation={props.navigation}
              sessionContext={props.sessionContext}
              nextStage={props.nextStage}
              tenantType={props.tenantType}
              language={language}
              country={country}
            />
          </Route>
          <Route path="/admin-printer-consents">
            <ECPContainer
              accessToken={props.accessToken}
              authProvider={props.authProvider}
              country={country}
              language={language}
              navigation={props.navigation}
              nextStage={props.nextStage}
              sessionContext={props.sessionContext}
            />
          </Route>
          {(props.sessionContext?.onboardingContext?.entry === 'appOobe' ||
            props.sessionContext?.onboardingContext?.entry === 'earlyOobe') && (
            <Route path="/consumer-printer-consents">
              {ffWfhOd && httpStatusCode === HTTP_STATUS_CODE ? (
                <WFHContainer
                  accessToken={props.accessToken}
                  authProvider={props.authProvider}
                  country={country}
                  language={language}
                  navigation={props.navigation}
                  nextStage={props.nextStage}
                  sessionContext={props.sessionContext}
                />
              ) : (
                <ODContainer
                  accessToken={props.accessToken}
                  authProvider={props.authProvider}
                  country={country}
                  language={language}
                  navigation={props.navigation}
                  nextStage={props.nextStage}
                  sessionContext={props.sessionContext}
                />
              )}
            </Route>
          )}
        </Switch>
      </ThemeProvider>
    </Router>
  )
}

export default App
