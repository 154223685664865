import { path } from 'ramda'

const activity = 'AssignOwnership-v01'
const screenPath = '/PortalConsents/'
const screenPath1 = '/PortalConsents/DataCollectionConsent/'

export const publishEvent = (event) => {
  const publishCdmEvents = path(['Shell', 'v1', 'analytics', 'publishCdmEvents'], window)

  if (!publishCdmEvents) {
    console.error('Shell.v1.analytics.publishCdmEvents is not defined')
    return
  }

  publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType: 'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      eventDetail: event,
      version: '1.4.0'
    }
  ])
}

// Events

export const ConsentsScreenDisplayedNewDomain = {
  action: 'ScreenDisplayed',
  activity,
  screenPath,
  screenName: 'DataCollectionConsent',
  screenMode: 'NewDomain',
  version: '1.1.0'
}

export const ConsentsScreenDisplayedExistingDomain = {
  action: 'ScreenDisplayed',
  activity,
  screenPath,
  screenName: 'DataCollectionConsent',
  screenMode: 'ExistingDomain',
  version: '1.1.0'
}

export const AcceptAllButtonClicked = {
  action: 'ControlButtonClicked',
  activity,
  screenPath,
  screenName: 'DataCollectionConsent',
  controlName: 'ConsentAcceptButton',
  screenMode: 'NewDomain',
  version: '1.1.0'
}

export const DeclineAllButtonClicked = {
  action: 'ControlButtonClicked',
  activity,
  screenPath,
  screenName: 'DataCollectionConsent',
  controlName: 'ConsentDeclineButton',
  screenMode: 'NewDomain',
  version: '1.1.0'
}

export const ManageConsentButtonClicked = {
  action: 'ControlButtonClicked',
  activity,
  screenPath,
  screenName: 'DataCollectionConsent',
  controlName: 'ConsentManageButton',
  screenMode: 'NewDomain',
  version: '1.1.0'
}

export const ManageConsentScreenDisplayed = {
  action: 'ScreenDisplayed',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  screenMode: 'NewDomain',
  version: '1.1.0'
}

export const AnalyticsToggleEnabled = {
  action: 'ControlToggleEnabled',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  controlName: 'AnalyticsToggle',
  screenMode: 'NewDomain',
  version: '1.1.0'
}

export const AnalyticsToggleDisabled = {
  action: 'ControlToggleDisabled',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  controlName: 'AnalyticsToggle',
  screenMode: 'NewDomain',
  version: '1.1.0'
}

export const DataTransferToggleEnabled = {
  action: 'ControlToggleEnabled',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  controlName: 'DataTransferToggle',
  screenMode: 'NewDomain',
  version: '1.1.0'
}

export const DataTransferToggleDisabled = {
  action: 'ControlToggleEnabled',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  controlName: 'DataTransferToggle',
  screenMode: 'NewDomain',
  version: '1.1.0'
}

export const AdvertisingToggleEnabled = {
  action: 'ControlToggleEnabled',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  screenMode: 'NewDomain',
  controlName: 'AdvertisingToggle',
  version: '1.1.0'
}

export const AdvertisingToggleDisabled = {
  action: 'ControlToggleDisabled',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  screenMode: 'NewDomain',
  controlName: 'AdvertisingToggle',
  version: '1.1.0'
}

export const PersonalizedSuggToggleEnabled = {
  action: 'ControlToggleEnabled',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  screenMode: 'NewDomain',
  controlName: 'PersonalizedSuggToggle',
  version: '1.1.0'
}

export const PersonalizedSuggToggleDisabled = {
  action: 'ControlToggleDisabled',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  screenMode: 'NewDomain',
  controlName: 'PersonalizedSuggToggle',
  version: '1.1.0'
}

export const ContinueConfirmButtonClickedAnalyticsOn = {
  action: 'ControlButtonClicked',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  controlName: 'ContinueConfirmButton',
  screenMode: 'AnalyticsOn',
  version: '1.1.0'
}

export const ContinueConfirmButtonClickedAnalyticsOff = {
  action: 'ControlButtonClicked',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  controlName: 'ContinueConfirmButton',
  screenMode: 'AnalyticsOff',
  version: '1.1.0'
}

export const ContinueButtonClicked = {
  action: 'ControlButtonClicked',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  screenMode: 'NewDomain',
  controlName: 'ContinueButton',
  version: '1.1.0'
}

export const BackButtonClicked = {
  action: 'ControlButtonClicked',
  activity,
  screenPath: screenPath1,
  screenName: 'ManagePrivacyConsent',
  screenMode: 'NewDomain',
  controlName: 'BackButton',
  version: '1.1.0'
}

export const ConsentRegistrationScreenDisplayed = {
  action: 'ScreenDisplayed',
  activity: 'PairingCode-v01',
  screenPath: screenPath,
  screenName: 'ConsentRegistrationSpinner',
  version: '1.1.0'
}

export const ExistingDomainScreenDisplayedAnalyticsOn = {
  action: 'ScreenDisplayed',
  activity,
  screenPath,
  screenName: 'DataCollectionConsent',
  screenMode: 'AnalyticsOn',
  version: '1.1.0'
}

export const ExistingDomainScreenDisplayedAnalyticsOff = {
  action: 'ScreenDisplayed',
  activity,
  screenPath,
  screenName: 'DataCollectionConsent',
  screenMode: 'AnalyticsOff',
  version: '1.1.0'
}
