import React from 'react'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { MainDiv } from '../styles/appStyles'
import { usePrinterSettingsCreateHook } from './hooks'

const PrinterConsentsCreate = ({
  navigation,
  sessionContext,
  nextStage,
  tenantType,
  language,
  country
}) => {
  usePrinterSettingsCreateHook(sessionContext, nextStage, tenantType, navigation, language, country)
  return (
    <MainDiv>
      <ProgressIndicator />
    </MainDiv>
  )
}

export default PrinterConsentsCreate
