import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router'
import {
  setUserPrinterConsents,
  getCraftData,
  getAdminData,
  postAdminConsents,
  getPrinterConsentsData,
  getPrinterData,
  postECPData,
  postODData,
  postODConsents
} from '../services/consents'

import {
  PRINTER_V1,
  SMB,
  requiredBasicPrinter,
  printerAnalytics,
  printerAdvertizing,
  printerPersonlization,
  piplDataTransfer,
  requiredBasicSmb,
  smbAnalytics,
  smbAdvertizing,
  ecpCustomerOrg,
  onBoardingDirector
} from '../constants/purposeID'

import { SUPPORTED_LANGUAGES } from '../constants/i18n'

import {
  AcceptAllButtonClicked,
  AdvertisingToggleDisabled,
  AdvertisingToggleEnabled,
  AnalyticsToggleDisabled,
  AnalyticsToggleEnabled,
  DataTransferToggleEnabled,
  DataTransferToggleDisabled,
  ConsentRegistrationScreenDisplayed,
  ConsentsScreenDisplayedExistingDomain,
  ConsentsScreenDisplayedNewDomain,
  ContinueButtonClicked,
  ContinueConfirmButtonClickedAnalyticsOn,
  ContinueConfirmButtonClickedAnalyticsOff,
  DeclineAllButtonClicked,
  PersonalizedSuggToggleEnabled,
  PersonalizedSuggToggleDisabled,
  ExistingDomainScreenDisplayedAnalyticsOn,
  ExistingDomainScreenDisplayedAnalyticsOff,
  publishEvent
} from '../utils/analytics'
import {
  ConsentScreenDisplayedOD,
  ConsentControlDisplayedOD,
  ConsentScreenDisplayedWFH,
  ConsentControlDisplayedWFH,
  ConsentAcceptAllButtonClickedOD,
  ConsentDeclineAllButtonClickedOD,
  ConsentContinueButtonClickedWFH,
  ManageConsentContinueButtonClickedOD,
  ManageConsentDataTransferToggleEnabledOD,
  ManageConsentDataTransferToggleDisabledOD,
  ManageConsentAnalyticsToggleEnabledOD,
  ManageConsentAnalyticsToggleDisabledOD,
  ManageConsentAdvertisingToggleEnabledOD,
  ManageConsentAdvertisingToggleDisabledOD,
  ManageConsentPersonalizedSuggToggleEnabledOD,
  ManageConsentPersonalizedSuggToggleDisabledOD,
  ManageConsentDataTransferToggleEnabledWFH,
  ManageConsentDataTransferToggleDisabledWFH,
  ManageConsentAnalyticsToggleEnabledWFH,
  ManageConsentAnalyticsToggleDisabledWFH,
  ManageConsentAdvertisingToggleEnabledWFH,
  ManageConsentAdvertisingToggleDisabledWFH,
  ManageConsentPersonalizedSuggToggleEnabledWFH,
  ManageConsentPersonalizedSuggToggleDisabledWFH
} from '../utils/analyticsOD'
import { CHINA, OPTEDIN, OPTEDOUT, NOSELECTION } from '../utils/commons'

const unpackCraftData = (data) => {
  if (data.data) {
    return unpackCraftData(data.data)
  }
  if (Array.isArray(data) && data.length === 1) {
    return unpackCraftData(data[0])
  }
  return data
}

const getPurposeID = (data, matchVersion) => {
  const values = data
  const consents = {}
  values.forEach(({ resourceId, state, purposeId, deviceId, verbiageValue }) => {
    consents[resourceId] = {
      state,
      purposeId,
      verbiageValue,
      deviceId
    }
  })
  return consents
}

const setLanguageAndCountryForPersonal = (language, country) => {
  if (language in SUPPORTED_LANGUAGES)
    return { supportedLanguage: language, supportedCountry: country }
  else return { supportedLanguage: 'en', supportedCountry: 'gb' }
}

// This function is used for "Accept All" button click and "Decline All" button click
const setAllOptedIn = (alpacaData, declineOptedIn = false) => {
  const optedInValues = {}
  Object.entries(alpacaData).forEach(([key, value]) => {
    optedInValues[key] = { ...value }
    if (declineOptedIn) {
      if (
        optedInValues[key].purposeId === requiredBasicPrinter ||
        optedInValues[key].purposeId === requiredBasicSmb
      ) {
        optedInValues[key].state = 'OptedIn'
      } else {
        optedInValues[key].state = 'OptedOut'
      }
    } else {
      optedInValues[key].state = 'OptedIn'
    }
  })
  return optedInValues
}

const setAllOptedInForPrinter = (alpacaData, declineOptedIn = false) => {
  const optedInValues = []
  Object.entries(alpacaData).forEach(([key, value]) => {
    optedInValues[key] = { ...value }
    if (declineOptedIn) {
      if (optedInValues[key].purposeId === requiredBasicPrinter) {
        optedInValues[key].state = 'OptedIn'
      } else {
        optedInValues[key].state = 'OptedOut'
      }
    } else {
      optedInValues[key].state = 'OptedIn'
    }
  })
  return optedInValues
}

const setAllOptedInForOD = (alpacaData, declineOptedIn = false) => {
  const optedInValues = []
  Object.entries(alpacaData).forEach(([key, value], index) => {
    optedInValues[index] = { ...value }
    if (declineOptedIn) {
      if (optedInValues[index].purposeId === requiredBasicPrinter) {
        optedInValues[index].state = 'OptedIn'
      } else {
        optedInValues[index].state = 'OptedOut'
      }
    } else {
      optedInValues[index].state = 'OptedIn'
    }
  })
  return optedInValues
}

const getOptinValues = (alpacaData) => {
  return Object.values(alpacaData)
}

export const usePrinterSettingsHook = (
  navigation,
  sessionContext,
  nextStage,
  language,
  country,
  consentType = ''
) => {
  const { search } = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(search)
  const redirectUri = queryParams.get('redirectUri')

  const [alpacaData, setAlpacaData] = useState(null)
  const [craftData, setCraftData] = useState(null)
  const [modalCraftData, setModalCraftData] = useState(null)
  const [acceptAllButtonLoading, setAcceptAllLoading] = useState(false)
  const [declineAllButtonLoading, setDeclineAllLoading] = useState(false)
  const [continueButtonLoading, setContinueLoading] = useState(false)
  const redirect = navigation || history

  const [requiredPrivacy, setRequiredPrivacy] = useState(true)
  const [analytics, setAnalytics] = useState(false)
  const [advertising, setAdvertising] = useState(false)
  const [personalizedSuggestions, setPersonalizedSuggestions] = useState(false)
  const [defaultConsentContent, setDefaultConsentContent] = useState(null)
  const [appAnalyticsContent, setAppAnalyticsConsentContent] = useState(null)
  const [advertisingContent, setAdvertisingConsentContent] = useState(null)
  const [personalizedSuggestionsContent, setpersonalizedSuggestionsConsentContent] = useState(null)
  const purposeIdList = [
    requiredBasicPrinter,
    printerAnalytics,
    printerAdvertizing,
    printerPersonlization
  ]

  const { supportedLanguage } = setLanguageAndCountryForPersonal(language, country)

  const setPrivacySettings = (data) => {
    const values = getOptinValues(data)
    values.forEach(({ purposeId, state, verbiageValue }) => {
      if (purposeId === requiredBasicPrinter) {
        setRequiredPrivacy(true)
        setDefaultConsentContent(verbiageValue)
      }
      if (purposeId === printerAnalytics) {
        if (state === 'OptedIn') {
          setAnalytics(true)
        }
        if (state === 'NoSelection' || state === 'OptedOut') {
          setAnalytics(false)
        }
        setAppAnalyticsConsentContent(verbiageValue)
      }
      if (purposeId === printerAdvertizing) {
        if (state === 'OptedIn') {
          setAdvertising(true)
        }
        if (state === 'NoSelection' || state === 'OptedOut') {
          setAdvertising(false)
        }
        setAdvertisingConsentContent(verbiageValue)
      }
      if (purposeId === printerPersonlization) {
        if (state === 'OptedIn') {
          setPersonalizedSuggestions(true)
        }
        if (state === 'NoSelection' || state === 'OptedOut') {
          setPersonalizedSuggestions(false)
        }
        setpersonalizedSuggestionsConsentContent(verbiageValue)
      }
    })
  }

  useEffect(() => {
    async function getCraftStuff() {
      try {
        const craftResponse = await getCraftData(
          'smb-personal-consents',
          `${language}_${country.toUpperCase()}`,
          consentType ? 'od' : 'printer-consents'
        )
        const unpackedData = unpackCraftData(craftResponse)
        setCraftData(unpackedData)
      } catch (e) {
        console.warn('Error thrown in getCraftStuff api call ', e)
      }
    }

    async function getModalCraftStuff() {
      try {
        const craftResponse = await getCraftData(
          'smb-personal-choice',
          `${language}_${country.toUpperCase()}`,
          consentType ? 'od-update' : 'printer-consents-update'
        )
        const unpackedData = unpackCraftData(craftResponse)
        setModalCraftData(unpackedData)
      } catch (e) {
        console.warn('Error thrown in getModalCraftStuff api call ', e)
      }
    }

    if (!craftData) {
      getCraftStuff()
    }
    if (!modalCraftData) {
      getModalCraftStuff()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getAlpacaStuff() {
      try {
        const alpacaResponse = await getPrinterData(supportedLanguage, purposeIdList)
        const data = alpacaResponse.data
        data.map((item) => {
          return (item.state = 'NoSelection')
        })

        setAlpacaData(data)
        const defaultValues = {
          'bizops.device.basicfunct.-.printer-v1.print.npf': true,
          'prodsupport.device.telemetry.-.printer-v1.print': false,
          'advertising.device.marketing.-.printer-v1.print': false,
          'personalization.device.personalization.-.printer-v1.print': false
        }
        publishEvent(ConsentsScreenDisplayedNewDomain)
        setPrivacySettings(data, defaultValues)
      } catch (e) {
        e.robustInfo = {
          alpacaCall: 'true'
        }
        console.warn('Error thrown in getAlpacaStuff api call ', e)
      }
    }

    if (!alpacaData) {
      getAlpacaStuff()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alpacaData])

  const setEachOptIn = (alpacaData) => {
    const optedInValues = []
    Object.entries(alpacaData).forEach(([key, value]) => {
      if (isPurposeIDUpdated(value)) {
        optedInValues[key] = { ...value }
        optedInValues[key].state = value.state === 'OptedIn' ? 'OptedOut' : 'OptedIn'
      } else {
        optedInValues[key] = { ...value }
        optedInValues[key].state = value.state === 'NoSelection' ? 'OptedOut' : value.state
      }
    })
    return optedInValues
  }
  const isPurposeIDUpdated = ({ purposeId, state }) => {
    if (purposeId === requiredBasicPrinter) {
      if ((state === 'OptedIn' && !requiredPrivacy) || (state !== 'OptedIn' && requiredPrivacy)) {
        return true
      }
    }
    if (purposeId === printerAnalytics) {
      if ((state === 'OptedIn' && !analytics) || (state !== 'OptedIn' && analytics)) {
        return true
      }
    }
    if (purposeId === printerAdvertizing) {
      if ((state === 'OptedIn' && !advertising) || (state !== 'OptedIn' && advertising)) {
        return true
      }
    }
    if (purposeId === printerPersonlization) {
      if (
        (state === 'OptedIn' && !personalizedSuggestions) ||
        (state !== 'OptedIn' && personalizedSuggestions)
      ) {
        return true
      }
    }
  }

  const handleContinueClick = async () => {
    setContinueLoading(true)
    const newAlpacaData = setEachOptIn(alpacaData)
    publishEvent(ContinueButtonClicked)
    Object.entries(newAlpacaData).forEach(([key, value]) => {
      if (value.purposeId === printerAnalytics) {
        if (value.state === 'OptedIn') {
          publishEvent(AnalyticsToggleEnabled)
        } else {
          publishEvent(AnalyticsToggleDisabled)
        }
      }

      if (value.purposeId === printerAdvertizing) {
        if (value.state === 'OptedIn') {
          publishEvent(AdvertisingToggleEnabled)
        } else {
          publishEvent(AdvertisingToggleDisabled)
        }
      }

      if (value.purposeId === printerPersonlization) {
        if (value.state === 'OptedIn') {
          publishEvent(PersonalizedSuggToggleEnabled)
        } else {
          publishEvent(PersonalizedSuggToggleDisabled)
        }
      }
    })
    newAlpacaData.map((item) => delete item.verbiageValue)

    if (sessionContext) {
      nextStage({
        result: 'success',
        output: {
          user: {
            consents: newAlpacaData
          }
        },
        xCorrelationId: sessionContext.xCorrelationId
      })
    } else {
      redirect.push(redirectUri)
    }
  }

  const handleClick = async () => {
    setAcceptAllLoading(true)
    publishEvent(AcceptAllButtonClicked)
    const newAlpacaData = setAllOptedInForPrinter(alpacaData)
    newAlpacaData.map((item) => delete item.verbiageValue)

    if (sessionContext) {
      nextStage({
        result: 'success',
        output: {
          user: {
            consents: newAlpacaData
          }
        },
        xCorrelationId: sessionContext.xCorrelationId
      })
    } else {
      redirect.push(redirectUri)
    }
  }

  const declineAllClick = async () => {
    setDeclineAllLoading(true)
    publishEvent(DeclineAllButtonClicked)
    const newAlpacaData = setAllOptedInForPrinter(alpacaData, true)
    newAlpacaData.map((item) => delete item.verbiageValue)

    if (sessionContext) {
      nextStage({
        result: 'success',
        output: {
          user: {
            consents: newAlpacaData
          }
        },
        xCorrelationId: sessionContext.xCorrelationId
      })
    } else {
      redirect.push(redirectUri)
    }
  }

  return {
    handleClick,
    handleContinueClick,
    declineAllClick,
    setRequiredPrivacy,
    setAnalytics,
    setAdvertising,
    setPersonalizedSuggestions,
    noContent: !alpacaData,
    alpacaData: alpacaData,
    craftData,
    country,
    acceptAllButtonLoading,
    declineAllButtonLoading,
    continueButtonLoading,
    modalCraftData,
    requiredPrivacy,
    analytics,
    advertising,
    personalizedSuggestions,
    defaultConsentContent,
    appAnalyticsContent,
    advertisingContent,
    personalizedSuggestionsContent
  }
}

export const useAdminSettingsHook = (
  authProvider,
  navigation,
  sessionContext,
  nextStage,
  language,
  country
) => {
  const {
    onboardingContext: { productFamily }
  } = sessionContext
  const { search } = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(search)
  const redirectUri = queryParams.get('redirectUri')

  const [alpacaData, setAlpacaData] = useState(null)
  const [craftData, setCraftData] = useState(null)
  const [modalCraftData, setModalCraftData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [acceptAllButtonLoading, setAcceptAllLoading] = useState(false)
  const [declineAllButtonLoading, setDeclineAllLoading] = useState(false)
  const [continueButtonLoading, setContinueLoading] = useState(false)
  const [isAnalyticsOn, setIsAnalyticsOn] = useState(false)
  const [isAlreadyConsented, setIsAlreadyConsented] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const redirect = navigation || history

  const [requiredPrivacy, setRequiredPrivacy] = useState(true)
  const [analytics, setAnalytics] = useState(false)
  const [advertising, setAdvertising] = useState(false)
  const [defaultConsentContent, setDefaultConsentContent] = useState(null)
  const [appAnalyticsContent, setAppAnalyticsConsentContent] = useState(null)
  const [advertisingContent, setAdvertisingConsentContent] = useState(null)
  const purposeIdList = [requiredBasicSmb, smbAnalytics, smbAdvertizing]

  const objectFilter = (obj, predicate) => {
    return Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => ((res[key] = obj[key]), res), {})
  }

  const setPrivacySettings = (data) => {
    const values = getOptinValues(data)
    values.forEach(({ purposeId, state, verbiageValue }) => {
      if (purposeId === requiredBasicSmb) {
        setRequiredPrivacy(true)
        setDefaultConsentContent(verbiageValue)
      }
      if (purposeId === smbAnalytics) {
        if (state === 'OptedIn') {
          setAnalytics(true)
        }
        if (state === 'NoSelection' || state === 'OptedOut') {
          setAnalytics(false)
        }
        setAppAnalyticsConsentContent(verbiageValue)
      }
      if (purposeId === smbAdvertizing) {
        if (state === 'OptedIn') {
          setAdvertising(true)
        }
        if (state === 'NoSelection' || state === 'OptedOut') {
          setAdvertising(false)
        }
        setAdvertisingConsentContent(verbiageValue)
      }
    })
  }

  useEffect(() => {
    async function getAccessToken() {
      setAccessToken(await authProvider.getAccessToken())
    }
    getAccessToken()
  }, [authProvider])

  useEffect(() => {
    async function getCraftStuff() {
      try {
        const craftResponse = await getCraftData(
          'smb-admin-consents',
          `${language}_${country.toUpperCase()}`,
          // LFP_DUNE for '/go' flow
          productFamily === 'LFP_DUNE' ? 'go-printer-consents' : 'admin-printer-consents'
        )
        const unpackedData = unpackCraftData(craftResponse)
        setCraftData(unpackedData)
      } catch (e) {
        console.warn('Error thrown in getCraftStuff api call ', e)
      }
    }

    async function getModalCraftStuff() {
      try {
        const craftResponse = await getCraftData(
          'smb-admin-choice',
          `${language}_${country.toUpperCase()}`,
          // LFP_DUNE for '/go' flow
          productFamily === 'LFP_DUNE'
            ? 'go-printer-consents-update'
            : 'admin-printer-consents-update'
        )
        const unpackedData = unpackCraftData(craftResponse)
        setModalCraftData(unpackedData)
      } catch (e) {
        console.warn('Error thrown in getModalCraftStuff api call ', e)
      }
    }

    if (!craftData) {
      getCraftStuff()
    }
    if (!modalCraftData) {
      getModalCraftStuff()
    }
  }, [])

  useEffect(() => {
    async function getAlpacaStuff() {
      try {
        const alpacaResponse = await getAdminData(language, accessToken, purposeIdList)
        const data = getPurposeID(alpacaResponse.data, SMB)
        // filtering based on if any item state has NoSelection
        const consentedAlpacaData = data && objectFilter(data, (el) => el.state !== 'NoSelection')
        const hasAlreadyConsented = Object.keys(consentedAlpacaData).length > 0
        setIsAlreadyConsented(hasAlreadyConsented)

        if (hasAlreadyConsented) {
          publishEvent(ConsentsScreenDisplayedExistingDomain)
          const analyticsOnData = objectFilter(data, (el) => {
            return el.purposeId === smbAnalytics && el.state === 'OptedIn'
          })
          const analyticsIsOn = Object.keys(analyticsOnData).length > 0
          setIsAnalyticsOn(analyticsIsOn)
          if (analyticsIsOn) {
            publishEvent(ExistingDomainScreenDisplayedAnalyticsOn)
          } else {
            publishEvent(ExistingDomainScreenDisplayedAnalyticsOff)
          }
        } else {
          publishEvent(ConsentsScreenDisplayedNewDomain)
        }

        setAlpacaData(data)
        const defaultValues = {
          'bizops.customerOrg.basicfunct.smb.printer.print.npf': true,
          'prodsupport.customerOrg.telemetry.smb.printer.print': false,
          'advertising.customerOrg.marketing.smb.printer.print': false
        }
        setPrivacySettings(data, defaultValues)
      } catch (e) {
        e.robustInfo = {
          alpacaCall: 'true'
        }
        console.warn('Error thrown in getAlpacaStuff api call ', e)
      }
    }

    if (!alpacaData && accessToken) {
      getAlpacaStuff()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alpacaData, accessToken])

  const setEachOptIn = (alpacaData) => {
    const optedInValues = {}
    Object.entries(alpacaData).forEach(([key, value]) => {
      if (isPurposeIDUpdated(value)) {
        optedInValues[key] = { ...value }
        optedInValues[key].state = value.state === 'OptedIn' ? 'OptedOut' : 'OptedIn'
      } else {
        optedInValues[key] = { ...value }
        optedInValues[key].state = value.state === 'NoSelection' ? 'OptedOut' : value.state
      }
    })
    return optedInValues
  }

  const isPurposeIDUpdated = ({ purposeId, state }) => {
    if (purposeId === requiredBasicSmb) {
      if ((state === 'OptedIn' && !requiredPrivacy) || (state !== 'OptedIn' && requiredPrivacy)) {
        return true
      }
    }
    if (purposeId === smbAnalytics) {
      if ((state === 'OptedIn' && !analytics) || (state !== 'OptedIn' && analytics)) {
        return true
      }
    }
    if (purposeId === smbAdvertizing) {
      if ((state === 'OptedIn' && !advertising) || (state !== 'OptedIn' && advertising)) {
        return true
      }
    }
  }

  const handleContinueClick = async () => {
    setContinueLoading(true)
    publishEvent(ContinueButtonClicked)

    const newAlpacaData = setEachOptIn(alpacaData)
    Object.entries(newAlpacaData).forEach(([key, value]) => {
      if (value.purposeId === smbAnalytics) {
        if (value.state === 'OptedIn') {
          publishEvent(AnalyticsToggleEnabled)
        } else {
          publishEvent(AnalyticsToggleDisabled)
        }
      }

      if (value.purposeId === smbAdvertizing) {
        if (value.state === 'OptedIn') {
          publishEvent(AdvertisingToggleEnabled)
        } else {
          publishEvent(AdvertisingToggleDisabled)
        }
      }
    })
    let data
    try {
      const response = await postAdminConsents(language, newAlpacaData, accessToken)
      data = response?.data
    } catch (e) {
      console.warn('Error thrown in click handler ', e)
      return setLoading(false)
    }
    if (data) {
      if (sessionContext) {
        nextStage({ result: 'success' })
      } else {
        redirect.push(redirectUri)
      }
    }
  }

  const handleClick = async () => {
    setAcceptAllLoading(true)
    publishEvent(AcceptAllButtonClicked)
    const newAlpacaData = setAllOptedIn(alpacaData)
    let data
    try {
      const response = await postAdminConsents(language, newAlpacaData, accessToken)
      data = response?.data
    } catch (e) {
      console.warn('Error thrown in click handler ', e)
      return setLoading(false)
    }
    if (data) {
      if (sessionContext) {
        nextStage({ result: 'success' })
      } else {
        redirect.push(redirectUri)
      }
    }
  }

  const declineAllClick = async () => {
    setDeclineAllLoading(true)
    publishEvent(DeclineAllButtonClicked)

    const newAlpacaData = setAllOptedIn(alpacaData, true)
    let data
    try {
      const response = await postAdminConsents(language, newAlpacaData, accessToken)
      data = response?.data
    } catch (e) {
      console.warn('Error thrown in click handler ', e)
      return setLoading(false)
    }
    if (data) {
      if (sessionContext) {
        nextStage({ result: 'success' })
      } else {
        redirect.push(redirectUri)
      }
    }
  }

  const consentedContinueClick = () => {
    setLoading(true)
    if (isAnalyticsOn) {
      publishEvent(ContinueConfirmButtonClickedAnalyticsOn)
    } else {
      publishEvent(ContinueConfirmButtonClickedAnalyticsOff)
    }
    if (sessionContext) {
      nextStage({ result: 'success' })
    } else {
      redirect.push(redirectUri)
    }
  }

  return {
    handleClick,
    handleContinueClick,
    declineAllClick,
    setRequiredPrivacy,
    setAnalytics,
    setAdvertising,
    consentedContinueClick,
    noContent: !alpacaData,
    alpacaData: alpacaData,
    craftData,
    country,
    loading,
    acceptAllButtonLoading,
    declineAllButtonLoading,
    continueButtonLoading,
    modalCraftData,
    requiredPrivacy,
    analytics,
    advertising,
    defaultConsentContent,
    appAnalyticsContent,
    advertisingContent,
    isAnalyticsOn,
    isAlreadyConsented
  }
}

export const usePostOobeSettingsHook = (authProvider, language, country) => {
  const [alpacaData, setAlpacaData] = useState(null)
  const [modalCraftData, setModalCraftData] = useState(null)
  const [accessToken, setAccessToken] = useState('')

  const [requiredPrivacy, setRequiredPrivacy] = useState(true)
  const [analytics, setAnalytics] = useState(false)
  const [advertising, setAdvertising] = useState(false)
  const [defaultConsentContent, setDefaultConsentContent] = useState(null)
  const [appAnalyticsContent, setAppAnalyticsConsentContent] = useState(null)
  const [advertisingContent, setAdvertisingConsentContent] = useState(null)
  const purposeIdList = [requiredBasicSmb, smbAnalytics, smbAdvertizing]

  const setPrivacySettings = (data) => {
    const values = getOptinValues(data)
    values.forEach(({ purposeId, state, verbiageValue }) => {
      if (purposeId === requiredBasicSmb) {
        setRequiredPrivacy(true)
        setDefaultConsentContent(verbiageValue)
      }
      if (purposeId === smbAnalytics) {
        if (state === 'OptedIn') {
          setAnalytics(true)
        }
        if (state === 'NoSelection' || state === 'OptedOut') {
          setAnalytics(false)
        }
        setAppAnalyticsConsentContent(verbiageValue)
      }
      if (purposeId === smbAdvertizing) {
        if (state === 'OptedIn') {
          setAdvertising(true)
        }
        if (state === 'NoSelection' || state === 'OptedOut') {
          setAdvertising(false)
        }
        setAdvertisingConsentContent(verbiageValue)
      }
    })
  }

  useEffect(() => {
    async function getAccessToken() {
      setAccessToken(await authProvider.getAccessToken())
    }
    getAccessToken()
  }, [authProvider])

  useEffect(() => {
    async function getModalCraftStuff() {
      try {
        const craftResponse = await getCraftData(
          'smb-admin-choice',
          `${language}_${country.toUpperCase()}`
        )
        const unpackedData = unpackCraftData(craftResponse)
        setModalCraftData(unpackedData)
      } catch (e) {
        console.warn('Error thrown in getModalCraftStuff api call ', e)
      }
    }

    if (!modalCraftData) {
      getModalCraftStuff()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getAlpacaStuff() {
      try {
        const alpacaResponse = await getAdminData(language, accessToken, purposeIdList)
        const data = getPurposeID(alpacaResponse.data, SMB)

        setAlpacaData(data)
        const defaultValues = {
          'bizops.customerOrg.basicfunct.smb.printer.print.npf': true,
          'prodsupport.customerOrg.telemetry.smb.printer.print': false,
          'advertising.customerOrg.marketing.smb.printer.print': false
        }
        setPrivacySettings(data, defaultValues)
      } catch (e) {
        e.robustInfo = {
          alpacaCall: 'true'
        }
        console.warn('Error thrown in getAlpacaStuff api call ', e)
      }
    }

    if (!alpacaData && accessToken) {
      getAlpacaStuff()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alpacaData, accessToken])

  const isPurposeIDUpdated = ({ purposeId, state }, e, param) => {
    if (purposeId === requiredBasicSmb) {
      if ((state === 'OptedIn' && !requiredPrivacy) || (state !== 'OptedIn' && requiredPrivacy)) {
        return true
      }
    }
    if (purposeId === param) {
      if ((state === 'OptedIn' && !e) || (state !== 'OptedIn' && e)) {
        return true
      }
    }
  }

  const setEachOptIn = (alpacaData, e, param) => {
    const optedInValues = {}
    Object.entries(alpacaData).forEach(([key, value]) => {
      if (isPurposeIDUpdated(value, e, param)) {
        optedInValues[key] = { ...value }
        optedInValues[key].state = value.state === 'OptedIn' ? 'OptedOut' : 'OptedIn'
      } else {
        optedInValues[key] = { ...value }
        optedInValues[key].state = value.state === 'NoSelection' ? 'OptedOut' : value.state
      }
    })
    return optedInValues
  }

  const handleClick = async (e, param) => {
    const newAlpacaData = setEachOptIn(alpacaData, e, param)
    let data
    try {
      const response = await postAdminConsents(language, newAlpacaData, accessToken)
      data = getPurposeID(response?.data, SMB)

      setAlpacaData(data)
    } catch (e) {
      console.warn('Error thrown in click handler ', e)
    }
  }

  return {
    handleClick,
    setAnalytics,
    setAdvertising,
    modalCraftData,
    analytics,
    advertising,
    requiredPrivacy,
    defaultConsentContent,
    appAnalyticsContent,
    advertisingContent
  }
}

export const usePrinterSettingsCreateHook = (
  sessionContext,
  nextStage,
  tenantType,
  navigation,
  language,
  country
) => {
  const { search } = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(search)
  const redirectUri = queryParams.get('redirectUri')

  const [alpacaData, setAlpacaData] = useState(null)
  const purposeIdList = [
    requiredBasicPrinter,
    printerAnalytics,
    printerAdvertizing,
    printerPersonlization
  ]
  const uuid = sessionContext.device?.uuid
  const consents = sessionContext.user?.consents
  const deviceId = decodeURIComponent(uuid)
  const redirect = navigation || history
  const { supportedLanguage, supportedCountry } = setLanguageAndCountryForPersonal(
    language,
    country
  )

  const getPlatformValue = () => {
    let platform = navigator?.userAgentData?.platform
    if (!platform) {
      if (navigator.userAgent.match(/Windows/i)) {
        platform = 'windows'
      } else if (navigator.userAgent.match(/Android/i)) {
        platform = 'android'
      } else if (navigator.userAgent.match(/iPhone/i)) {
        platform = 'ios'
      } else if (navigator.userAgent.match(/iPad/i)) {
        platform = 'ios'
      } else if (navigator.userAgent.match(/Mac/i)) {
        platform = 'mac'
      } else {
        platform = 'windows'
      }
    }

    return platform
  }

  useEffect(() => {
    async function getAlpacaStuff() {
      try {
        const alpacaResponse = await getPrinterConsentsData(
          deviceId,
          purposeIdList,
          supportedLanguage
        )
        const data = alpacaResponse.data
        setAlpacaData(data)
      } catch (e) {
        e.robustInfo = {
          alpacaCall: 'true'
        }
        console.warn('Error thrown in while getting consents ', e)
      }
    }

    async function setAlpacaStuff() {
      alpacaData.map((item) => {
        consents.map((consent) => {
          if (item.purposeId === consent.purposeId) {
            item.state = consent.state
          }
        })
      })

      const newAlpacaData = getPurposeID(alpacaData, PRINTER_V1)
      let data
      try {
        const response = await setUserPrinterConsents(
          deviceId,
          supportedLanguage,
          supportedCountry.toUpperCase(),
          getPlatformValue(),
          newAlpacaData
        )
        data = response?.data?.consents
      } catch (e) {
        e.robustInfo = {
          deviceId: deviceId
        }
        console.warn('Error thrown while setting consents ', e)
      }
      if (data) {
        if (sessionContext) {
          nextStage({ result: 'success' })
        } else {
          redirect.push(redirectUri)
        }
      }
    }

    publishEvent(ConsentRegistrationScreenDisplayed)

    if (tenantType === 'BusinessTransactionalSMB') {
      if (sessionContext) {
        nextStage({ result: 'success' })
      } else {
        redirect.push(redirectUri)
      }
    } else {
      if (!alpacaData) {
        getAlpacaStuff()
      } else {
        setAlpacaStuff()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alpacaData])
}

export const useECPConsentHook = (
  authProvider,
  navigation,
  sessionContext,
  nextStage,
  language,
  country,
  consentType
) => {
  const { search } = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(search)
  const redirectUri = queryParams.get('redirectUri')

  const [alpacaData, setAlpacaData] = useState(null)
  const [craftData, setCraftData] = useState(null)
  const [modalCraftData, setModalCraftData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [acceptAllButtonLoading, setAcceptAllLoading] = useState(false)
  const [declineAllButtonLoading, setDeclineAllLoading] = useState(false)
  const [continueButtonLoading, setContinueLoading] = useState(false)
  const [isAnalyticsOn, setIsAnalyticsOn] = useState(false)
  const [isAlreadyConsented, setIsAlreadyConsented] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const redirect = navigation || history

  const purposeIdList = [ecpCustomerOrg]

  const objectFilter = (obj, predicate) => {
    return Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => ((res[key] = obj[key]), res), {})
  }

  useEffect(() => {
    async function getAccessToken() {
      setAccessToken(await authProvider.getAccessToken())
    }
    getAccessToken()
  }, [authProvider])

  useEffect(() => {
    async function getCraftStuff() {
      try {
        const craftResponse = await getCraftData(
          'smb-admin-consents',
          `${language}_${country.toUpperCase()}`,
          consentType
        )
        const unpackedData = unpackCraftData(craftResponse)
        setCraftData(unpackedData)
      } catch (e) {
        console.warn('Error thrown in getCraftStuff api call ', e)
      }
    }

    if (!craftData) {
      getCraftStuff()
    }
  }, [])

  useEffect(() => {
    async function getAlpacaStuff() {
      try {
        const alpacaResponse = await postECPData(language, accessToken, purposeIdList)
        const data = getPurposeID(alpacaResponse.data, 'ecp')
        // filtering based on if any item state has NoSelection
        const consentedAlpacaData = data && objectFilter(data, (el) => el.state !== 'NoSelection')
        const hasAlreadyConsented = Object.keys(consentedAlpacaData).length > 0
        setIsAlreadyConsented(hasAlreadyConsented)

        if (hasAlreadyConsented) {
          publishEvent(ConsentsScreenDisplayedExistingDomain)
          const analyticsOnData = objectFilter(data, (el) => {
            return el.purposeId === smbAnalytics && el.state === 'OptedIn'
          })
          const analyticsIsOn = Object.keys(analyticsOnData).length > 0
          setIsAnalyticsOn(analyticsIsOn)
          if (analyticsIsOn) {
            publishEvent(ExistingDomainScreenDisplayedAnalyticsOn)
          } else {
            publishEvent(ExistingDomainScreenDisplayedAnalyticsOff)
          }
        } else {
          publishEvent(ConsentsScreenDisplayedNewDomain)
        }

        setAlpacaData(data)
        const defaultValues = {
          'bizops.customerOrg.basicfunct.smb.printer.print.npf': true,
          'prodsupport.customerOrg.telemetry.smb.printer.print': false,
          'advertising.customerOrg.marketing.smb.printer.print': false
        }
      } catch (e) {
        e.robustInfo = {
          alpacaCall: 'true'
        }
        console.warn('Error thrown in getAlpacaStuff api call ', e)
      }
    }

    if (!alpacaData && accessToken) {
      getAlpacaStuff()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alpacaData, accessToken])

  const handleClick = async () => {
    setAcceptAllLoading(true)
    publishEvent(AcceptAllButtonClicked)
    const newAlpacaData = setAllOptedIn(alpacaData)
    let data
    try {
      const response = await postAdminConsents(language, newAlpacaData, accessToken)
      data = response?.data
    } catch (e) {
      console.warn('Error thrown in click handler ', e)
      return setLoading(false)
    }
    if (data) {
      if (sessionContext) {
        nextStage({ result: 'success' })
      } else {
        redirect.push(redirectUri)
      }
    }
  }

  return {
    handleClick,
    noContent: !alpacaData,
    alpacaData: alpacaData,
    craftData,
    country,
    loading,
    acceptAllButtonLoading,
    declineAllButtonLoading,
    continueButtonLoading,
    modalCraftData,
    isAnalyticsOn,
    isAlreadyConsented
  }
}

export const useODConsentHook = (
  authProvider,
  navigation,
  sessionContext,
  nextStage,
  language,
  country,
  consentType,
  deviceId
) => {
  const { search } = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(search)
  const redirectUri = queryParams.get('redirectUri')
  const isPipl = country.toLowerCase() === CHINA
  const [callDataValueStore, setCallDataValueStore] = useState(false)
  const [dataValueStore, setDataValueStore] = useState(null)
  const [alpacaData, setAlpacaData] = useState(null)
  const [craftData, setCraftData] = useState(null)
  const [modalCraftData, setModalCraftData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [acceptAllButtonLoading, setAcceptAllLoading] = useState(false)
  const [declineAllButtonLoading, setDeclineAllLoading] = useState(false)
  const [continueButtonLoading, setContinueLoading] = useState(false)
  const [isAnalyticsOn, setIsAnalyticsOn] = useState(false)
  const [isAlreadyConsented, setIsAlreadyConsented] = useState(false)
  // const [accessToken, setAccessToken] = useState('')
  const redirect = navigation || history
  const [requiredPrivacy, setRequiredPrivacy] = useState(true)
  const [analytics, setAnalytics] = useState(false)
  const [advertising, setAdvertising] = useState(false)
  const [personalizedSuggestions, setPersonalizedSuggestions] = useState(false)
  const [dataTransfer, setDataTransfer] = useState(false)
  const [defaultConsentContent, setDefaultConsentContent] = useState(null)
  const [appAnalyticsContent, setAppAnalyticsConsentContent] = useState(null)
  const [advertisingContent, setAdvertisingConsentContent] = useState(null)
  const [personalizedSuggestionsContent, setpersonalizedSuggestionsConsentContent] = useState(null)
  const [dataTransferContent, setDataTransferConsentContent] = useState(null)

  const [purposeIdList, setPurposeIdList] = useState(
    !isPipl
      ? [requiredBasicPrinter, printerAnalytics, printerAdvertizing, printerPersonlization]
      : [
          requiredBasicPrinter,
          piplDataTransfer,
          printerAnalytics,
          printerAdvertizing,
          printerPersonlization
        ]
  )

  const objectFilter = (obj, predicate) => {
    return Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => ((res[key] = obj[key]), res), {})
  }

  const setPrivacySettings = (data) => {
    const values = getOptinValues(data)
    values.forEach(({ purposeId, state, verbiageValue }) => {
      if (purposeId === requiredBasicPrinter) {
        setRequiredPrivacy(true)
        setDefaultConsentContent(verbiageValue)
      }
      if (purposeId === piplDataTransfer) {
        if (state === OPTEDIN) {
          setDataTransfer(true)
        }
        if (state === NOSELECTION || state === OPTEDOUT) {
          setDataTransfer(false)
        }
        setDataTransferConsentContent(verbiageValue)
      }
      if (purposeId === printerAnalytics) {
        if (state === OPTEDIN) {
          setAnalytics(true)
        }
        if (state === NOSELECTION || state === OPTEDOUT) {
          setAnalytics(false)
        }
        setAppAnalyticsConsentContent(verbiageValue)
      }
      if (purposeId === printerAdvertizing) {
        if (state === OPTEDIN) {
          setAdvertising(true)
        }
        if (state === NOSELECTION || state === OPTEDOUT) {
          setAdvertising(false)
        }
        setAdvertisingConsentContent(verbiageValue)
      }
      if (purposeId === printerPersonlization) {
        if (state === OPTEDIN) {
          setPersonalizedSuggestions(true)
        }
        if (state === NOSELECTION || state === OPTEDOUT) {
          setPersonalizedSuggestions(false)
        }
        setpersonalizedSuggestionsConsentContent(verbiageValue)
      }
    })
  }

  // useEffect(() => {
  //   async function getAccessToken() {
  //     setAccessToken(await authProvider.getAccessToken())
  //   }
  //   getAccessToken()
  // }, [authProvider])

  useEffect(() => {
    const ValueStore = window.JWeb.Plugins.ValueStore
    async function getValueStoreStuff() {
      try {
        var keys = 'consent_preferences'
        if (ValueStore) {
          let keysArr = keys.split('|')
          let info = await ValueStore.get({
            keys: keysArr
          })
          if (info !== null && info.result.length > 0) {
            let storeValue = info.result[0].value
            if (typeof info.result[0].value === 'string') {
              storeValue = JSON.parse(info.result[0].value)
            }
            setDataValueStore(storeValue)
            storeValue &&
              setPurposeIdList(storeValue.printerConsent.map((value) => value.purposeId))
          }
          setCallDataValueStore(true)
        }
      } catch (e) {
        console.warn('Error thrown in value store data call ', e)
      }
    }

    if (!dataValueStore) {
      getValueStoreStuff()
    }
  }, [])

  useEffect(() => {
    async function getModalCraftStuff() {
      try {
        const craftResponse = await getCraftData(
          'smb-admin-choice',
          `${language}_${country.toUpperCase()}`,
          'od-update'
        )
        const unpackedData = unpackCraftData(craftResponse)
        setModalCraftData(unpackedData)
      } catch (e) {
        console.warn('Error thrown in getModalCraftStuff api call ', e)
      }
    }

    if (!modalCraftData) {
      getModalCraftStuff()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getCraftStuff() {
      try {
        const craftResponse = await getCraftData(
          'smb-admin-consents',
          `${language}_${country.toUpperCase()}`,
          consentType
        )
        const unpackedData = unpackCraftData(craftResponse)
        setCraftData(unpackedData)
      } catch (e) {
        console.warn('Error thrown in getCraftStuff api call ', e)
      }
    }

    if (!craftData) {
      getCraftStuff()
    }
  }, [])

  useEffect(() => {
    async function getAlpacaStuff() {
      try {
        const alpacaResponse = await postODData(language, purposeIdList, deviceId)
        const data = getPurposeID(alpacaResponse.data, 'print')

        publishEvent(ConsentScreenDisplayedOD)
        publishEvent(ConsentControlDisplayedOD)

        setAlpacaData(data)
        const defaultValues = !isPipl
          ? {
              'bizops.device.basicfunct.-.printer-v1.print.npf': true,
              'prodsupport.device.telemetry.-.printer-v1.print': false,
              'advertising.device.marketing.-.printer-v1.print': false,
              'personalization.device.personalization.-.printer-v1.print': false
            }
          : {
              'bizops.device.basicfunct.-.printer-v1.print.npf': true,
              'bizops.device.telemetry.unmanaged.transfer': false,
              'prodsupport.device.telemetry.-.printer-v1.print': false,
              'advertising.device.marketing.-.printer-v1.print': false,
              'personalization.device.personalization.-.printer-v1.print': false
            }
        setPrivacySettings(data, defaultValues)
        if (data && dataValueStore) {
          handleSpinnerContinueClick(data)
        }
      } catch (e) {
        e.robustInfo = {
          alpacaCall: 'true'
        }
        console.warn('Error thrown in getAlpacaStuff api call ', e)
      }
    }

    if (!alpacaData && callDataValueStore) {
      getAlpacaStuff()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alpacaData, callDataValueStore])

  const setEachOptIn = (alpacaData) => {
    const optedInValues = {}
    Object.entries(alpacaData).forEach(([key, value]) => {
      if (isPurposeIDUpdated(value)) {
        optedInValues[key] = { ...value }
        optedInValues[key].state = value.state === OPTEDIN ? OPTEDOUT : OPTEDIN
      } else {
        optedInValues[key] = { ...value }
        optedInValues[key].state = value.state === NOSELECTION ? OPTEDOUT : value.state
      }
    })
    return optedInValues
  }

  const setEachOptInAsValueStore = (alpacaData, valueStoreData) => {
    let filteredValueStoreState = (purposeID) =>
      valueStoreData.filter((data) => {
        return data.purposeId === purposeID
      })
    const optedInValues = {}
    Object.entries(alpacaData).forEach(([key, value]) => {
      optedInValues[key] = { ...value }
      optedInValues[key].state = filteredValueStoreState(optedInValues[key].purposeId)[0].state
    })
    return optedInValues
  }

  const setEachOptInODOutput = (alpacaData) => {
    const optedInValues = []
    Object.entries(alpacaData).forEach(([key, value], index) => {
      if (isPurposeIDUpdated(value)) {
        optedInValues[index] = { ...value }
        optedInValues[index].state = value.state === OPTEDIN ? OPTEDOUT : OPTEDIN
      } else {
        optedInValues[index] = { ...value }
        optedInValues[index].state = value.state === NOSELECTION ? OPTEDOUT : value.state
      }
    })
    return optedInValues
  }

  const isPurposeIDUpdated = ({ purposeId, state }) => {
    if (purposeId === requiredBasicPrinter) {
      if ((state === OPTEDIN && !requiredPrivacy) || (state !== OPTEDIN && requiredPrivacy)) {
        return true
      }
    }
    if (purposeId === printerAnalytics) {
      if ((state === OPTEDIN && !analytics) || (state !== OPTEDIN && analytics)) {
        return true
      }
    }
    if (purposeId === printerAdvertizing) {
      if ((state === OPTEDIN && !advertising) || (state !== OPTEDIN && advertising)) {
        return true
      }
    }
    if (purposeId === printerPersonlization) {
      if (
        (state === OPTEDIN && !personalizedSuggestions) ||
        (state !== OPTEDIN && personalizedSuggestions)
      ) {
        return true
      }
    }
    if (purposeId === piplDataTransfer) {
      if ((state === OPTEDIN && !dataTransfer) || (state !== OPTEDIN && dataTransfer)) {
        return true
      }
    }
  }

  const handleSpinnerContinueClick = async (alpacaData) => {
    const newAlpacaData = setEachOptInAsValueStore(alpacaData, dataValueStore.printerConsent)

    let data
    try {
      const response = await postODConsents(language, country, newAlpacaData, deviceId)
      data = response?.data
    } catch (e) {
      console.warn('Error thrown in click handler ', e)
      return setLoading(false)
    }
    if (data) {
      if (sessionContext) {
        console.log(
          'Successfully moving to next stage from Spinner with response data:',
          data['consents']
        )
        const ValueStore = window.JWeb.Plugins.ValueStore
        const keys = 'consent_preferences'
        if (ValueStore) {
          const options = {
            keys: [keys]
          }
          await ValueStore.remove(options)
        }

        nextStage({
          result: 'success',
          output: {
            user: {
              consents: data['consents']
            }
          },
          xCorrelationId: sessionContext.xCorrelationId
        })
      } else {
        console.log('Direct Redirect from Spinner')
        redirect.push(redirectUri)
      }
    }
  }

  const handleManageOptionsContinueClick = async () => {
    setContinueLoading(true)
    publishEvent(ManageConsentContinueButtonClickedOD)

    const newAlpacaData = setEachOptIn(alpacaData)
    const newAlpacaDataODData = setEachOptInODOutput(alpacaData)

    newAlpacaDataODData.map((item) => {
      delete item.verbiageValue
      delete item.deviceId
      return item
    })

    Object.entries(newAlpacaData).forEach(([key, value]) => {
      if (value.purposeId === piplDataTransfer) {
        if (value.state === OPTEDIN) {
          publishEvent(ManageConsentDataTransferToggleEnabledOD)
        } else {
          publishEvent(ManageConsentDataTransferToggleDisabledOD)
        }
      }

      if (value.purposeId === printerAnalytics) {
        if (value.state === OPTEDIN) {
          publishEvent(ManageConsentAnalyticsToggleEnabledOD)
        } else {
          publishEvent(ManageConsentAnalyticsToggleDisabledOD)
        }
      }

      if (value.purposeId === printerAdvertizing) {
        if (value.state === OPTEDIN) {
          publishEvent(ManageConsentAdvertisingToggleEnabledOD)
        } else {
          publishEvent(ManageConsentAdvertisingToggleDisabledOD)
        }
      }

      if (value.purposeId === printerPersonlization) {
        if (value.state === OPTEDIN) {
          publishEvent(ManageConsentPersonalizedSuggToggleEnabledOD)
        } else {
          publishEvent(ManageConsentPersonalizedSuggToggleDisabledOD)
        }
      }
    })

    let data
    try {
      const response = await postODConsents(language, country, newAlpacaData, deviceId)
      data = response?.data
    } catch (e) {
      console.warn('Error thrown in click handler ', e)
      return setLoading(false)
    }
    if (data) {
      if (sessionContext) {
        console.log(
          'Successfully moving to next stage from Manage Options with response data:',
          data['consents']
        )
        nextStage({
          result: 'success',
          output: {
            user: {
              consents: data['consents']
            }
          },
          xCorrelationId: sessionContext.xCorrelationId
        })
      } else {
        console.log('Direct Redirect from Accept All')
        redirect.push(redirectUri)
      }
    }
  }

  const handleConsentAcceptAllClick = async () => {
    setAcceptAllLoading(true)
    publishEvent(ConsentAcceptAllButtonClickedOD)

    const newAlpacaData = setAllOptedInForOD(alpacaData)
    const patchAlpacaData = setAllOptedIn(alpacaData)

    newAlpacaData.map((item) => {
      delete item.verbiageValue
      delete item.deviceId
      return item
    })

    let data
    try {
      const response = await postODConsents(language, country, patchAlpacaData, deviceId)
      data = response?.data
    } catch (e) {
      console.warn('Error thrown in click handler ', e)
      return setLoading(false)
    }
    if (data) {
      if (sessionContext) {
        console.log(
          'Successfully moving to  next stage from Accept All with response data:',
          data['consents']
        )
        nextStage({
          result: 'success',
          output: {
            user: {
              consents: data['consents']
            }
          },
          xCorrelationId: sessionContext.xCorrelationId
        })
      } else {
        console.log('Direct Redirect from Accept All')
        redirect.push(redirectUri)
      }
    }
  }

  const handleConsentDeclineAllClick = async () => {
    setDeclineAllLoading(true)
    publishEvent(ConsentDeclineAllButtonClickedOD)

    const newAlpacaData = setAllOptedInForOD(alpacaData, true)
    const newPatchAlpacaData = setAllOptedIn(alpacaData, true)

    newAlpacaData.map((item) => {
      delete item.verbiageValue
      delete item.deviceId
      return item
    })

    let data
    try {
      const response = await postODConsents(language, country, newPatchAlpacaData, deviceId)
      data = response?.data
    } catch (e) {
      console.warn('Error thrown in click handler ', e)
      return setLoading(false)
    }
    if (data) {
      if (sessionContext) {
        console.log(
          'Successfully moving to next stage from Decline All with response data:',
          data['consents']
        )
        nextStage({
          result: 'success',
          output: {
            user: {
              consents: newAlpacaData
            }
          },
          xCorrelationId: sessionContext.xCorrelationId
        })
      } else {
        console.log('Direct Redirect from Decline All')
        redirect.push(redirectUri)
      }
    }
  }

  return {
    handleConsentAcceptAllClick,
    handleConsentDeclineAllClick,
    handleManageOptionsContinueClick,
    setRequiredPrivacy,
    setAnalytics,
    setAdvertising,
    setPersonalizedSuggestions,
    setDataTransfer,
    dataValueStore,
    noContent: !alpacaData,
    alpacaData: alpacaData,
    craftData,
    country,
    loading,
    acceptAllButtonLoading,
    declineAllButtonLoading,
    continueButtonLoading,
    modalCraftData,
    requiredPrivacy,
    analytics,
    advertising,
    dataTransfer,
    personalizedSuggestions,
    defaultConsentContent,
    appAnalyticsContent,
    advertisingContent,
    personalizedSuggestionsContent,
    dataTransferContent,
    isAnalyticsOn,
    isAlreadyConsented
  }
}

export const useWFHConsentHook = (
  authProvider,
  navigation,
  sessionContext,
  nextStage,
  language,
  country,
  consentType,
  deviceId
) => {
  const { search } = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(search)
  const redirectUri = queryParams.get('redirectUri')
  const isPipl = country.toLowerCase() === CHINA
  const [alpacaData, setAlpacaData] = useState(null)
  const [craftData, setCraftData] = useState(null)
  const [modalCraftData, setModalCraftData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [acceptAllButtonLoading, setAcceptAllLoading] = useState(false)
  const [declineAllButtonLoading, setDeclineAllLoading] = useState(false)
  const [continueButtonLoading, setContinueLoading] = useState(false)
  const [isAnalyticsOn, setIsAnalyticsOn] = useState(false)
  const [isAlreadyConsented, setIsAlreadyConsented] = useState(false)
  // const [accessToken, setAccessToken] = useState('')
  const redirect = navigation || history
  const [requiredPrivacy, setRequiredPrivacy] = useState(true)
  const [analytics, setAnalytics] = useState(false)
  const [advertising, setAdvertising] = useState(false)
  const [personalizedSuggestions, setPersonalizedSuggestions] = useState(false)
  const [dataTransfer, setDataTransfer] = useState(false)
  const [defaultConsentContent, setDefaultConsentContent] = useState(null)
  const [appAnalyticsContent, setAppAnalyticsConsentContent] = useState(null)
  const [advertisingContent, setAdvertisingConsentContent] = useState(null)
  const [personalizedSuggestionsContent, setpersonalizedSuggestionsConsentContent] = useState(null)
  const [dataTransferContent, setDataTransferConsentContent] = useState(null)

  const purposeIdList = !isPipl
    ? [requiredBasicPrinter, printerAnalytics, printerAdvertizing, printerPersonlization]
    : [
        requiredBasicPrinter,
        piplDataTransfer,
        printerAnalytics,
        printerAdvertizing,
        printerPersonlization
      ]

  const objectFilter = (obj, predicate) => {
    return Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => ((res[key] = obj[key]), res), {})
  }

  const setPrivacySettings = (data) => {
    const values = getOptinValues(data)
    values.forEach(({ purposeId, state, verbiageValue }) => {
      if (purposeId === requiredBasicPrinter) {
        setRequiredPrivacy(true)
        setDefaultConsentContent(verbiageValue)
      }
      if (purposeId === piplDataTransfer) {
        if (state === OPTEDIN) {
          setDataTransfer(true)
        }
        if (state === NOSELECTION || state === OPTEDOUT) {
          setDataTransfer(false)
        }
        setDataTransferConsentContent(verbiageValue)
      }
      if (purposeId === printerAnalytics) {
        if (state === OPTEDIN) {
          setAnalytics(true)
        }
        if (state === NOSELECTION || state === OPTEDOUT) {
          setAnalytics(false)
        }
        setAppAnalyticsConsentContent(verbiageValue)
      }
      if (purposeId === printerAdvertizing) {
        if (state === OPTEDIN) {
          setAdvertising(true)
        }
        if (state === NOSELECTION || state === OPTEDOUT) {
          setAdvertising(false)
        }
        setAdvertisingConsentContent(verbiageValue)
      }
      if (purposeId === printerPersonlization) {
        if (state === OPTEDIN) {
          setPersonalizedSuggestions(true)
        }
        if (state === NOSELECTION || state === OPTEDOUT) {
          setPersonalizedSuggestions(false)
        }
        setpersonalizedSuggestionsConsentContent(verbiageValue)
      }
    })
  }

  // useEffect(() => {
  //   async function getAccessToken() {
  //     setAccessToken(await authProvider.getAccessToken())
  //   }
  //   getAccessToken()
  // }, [authProvider])

  useEffect(() => {
    async function getCraftStuff() {
      try {
        const craftResponse = await getCraftData(
          'wfh-privacy',
          `${language}_${country.toUpperCase()}`,
          consentType
        )
        const unpackedData = unpackCraftData(craftResponse)
        setCraftData(unpackedData)
      } catch (e) {
        console.warn('Error thrown in getCraftStuff api call ', e)
      }
    }

    if (!craftData) {
      getCraftStuff()
    }
  }, [])

  useEffect(() => {
    async function getAlpacaStuff() {
      try {
        const alpacaResponse = await postODData(language, purposeIdList, deviceId)
        const data = getPurposeID(alpacaResponse.data, 'print')
        // filtering based on if any item state has NoSelection
        const consentedAlpacaData = data && objectFilter(data, (el) => el.state !== NOSELECTION)
        const hasAlreadyConsented = Object.keys(consentedAlpacaData).length > 0
        setIsAlreadyConsented(hasAlreadyConsented)

        if (hasAlreadyConsented) {
          const analyticsOnData = objectFilter(data, (el) => {
            return el.purposeId === printerAnalytics && el.state === OPTEDIN
          })
          const analyticsIsOn = Object.keys(analyticsOnData).length > 0
          setIsAnalyticsOn(analyticsIsOn)
        }
        publishEvent(ConsentScreenDisplayedWFH)
        publishEvent(ConsentControlDisplayedWFH)

        setAlpacaData(data)
        const defaultValues = !isPipl
          ? {
              'bizops.device.basicfunct.-.printer-v1.print.npf': true,
              'prodsupport.device.telemetry.-.printer-v1.print': false,
              'advertising.device.marketing.-.printer-v1.print': false,
              'personalization.device.personalization.-.printer-v1.print': false
            }
          : {
              'bizops.device.basicfunct.-.printer-v1.print.npf': true,
              'bizops.device.telemetry.unmanaged.transfer': false,
              'prodsupport.device.telemetry.-.printer-v1.print': false,
              'advertising.device.marketing.-.printer-v1.print': false,
              'personalization.device.personalization.-.printer-v1.print': false
            }
        setPrivacySettings(data, defaultValues)
      } catch (e) {
        e.robustInfo = {
          alpacaCall: 'true'
        }
        console.warn('Error thrown in getAlpacaStuff api call ', e)
      }
    }

    if (!alpacaData) {
      getAlpacaStuff()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alpacaData])

  const setEachOptIn = (alpacaData) => {
    const optedInValues = []
    Object.entries(alpacaData).forEach(([key, value]) => {
      if (isPurposeIDUpdated(value)) {
        optedInValues[key] = { ...value }
        optedInValues[key].state = value.state === OPTEDIN ? OPTEDOUT : OPTEDIN
      } else {
        optedInValues[key] = { ...value }
        optedInValues[key].state = value.state === NOSELECTION ? OPTEDOUT : value.state
      }
    })
    return optedInValues
  }

  const isPurposeIDUpdated = ({ purposeId, state }) => {
    if (purposeId === requiredBasicPrinter) {
      if ((state === OPTEDIN && !requiredPrivacy) || (state !== OPTEDIN && requiredPrivacy)) {
        return true
      }
    }
    if (purposeId === printerAnalytics) {
      if ((state === OPTEDIN && !analytics) || (state !== OPTEDIN && analytics)) {
        return true
      }
    }
    if (purposeId === printerAdvertizing) {
      if ((state === OPTEDIN && !advertising) || (state !== OPTEDIN && advertising)) {
        return true
      }
    }
    if (purposeId === printerPersonlization) {
      if (
        (state === OPTEDIN && !personalizedSuggestions) ||
        (state !== OPTEDIN && personalizedSuggestions)
      ) {
        return true
      }
    }
    if (purposeId === piplDataTransfer) {
      if ((state === OPTEDIN && !dataTransfer) || (state !== OPTEDIN && dataTransfer)) {
        return true
      }
    }
  }

  const handleConsentContinueClick = () => {
    setContinueLoading(true)
    publishEvent(ConsentContinueButtonClickedWFH)

    const newAlpacaData = setEachOptIn(alpacaData)
    var newAlpacaWFHData = []
    Object.entries(newAlpacaData).forEach(([key, value]) => {
      newAlpacaWFHData.push(value)
      if (value.purposeId === piplDataTransfer) {
        if (value.state === OPTEDIN) {
          publishEvent(ManageConsentDataTransferToggleEnabledWFH)
        } else {
          publishEvent(ManageConsentDataTransferToggleDisabledWFH)
        }
      }

      if (value.purposeId === printerAnalytics) {
        if (value.state === OPTEDIN) {
          publishEvent(ManageConsentAnalyticsToggleEnabledWFH)
        } else {
          publishEvent(ManageConsentAnalyticsToggleDisabledWFH)
        }
      }

      if (value.purposeId === printerAdvertizing) {
        if (value.state === OPTEDIN) {
          publishEvent(ManageConsentAdvertisingToggleEnabledWFH)
        } else {
          publishEvent(ManageConsentAdvertisingToggleDisabledWFH)
        }
      }

      if (value.purposeId === printerPersonlization) {
        if (value.state === OPTEDIN) {
          publishEvent(ManageConsentPersonalizedSuggToggleEnabledWFH)
        } else {
          publishEvent(ManageConsentPersonalizedSuggToggleDisabledWFH)
        }
      }
    })

    newAlpacaWFHData.map((item) => {
      delete item.verbiageValue
      delete item.deviceId
    })

    if (sessionContext) {
      console.log(
        'Successfully moving to  next stage from Continue with response data:',
        newAlpacaWFHData
      )
      nextStage({
        result: 'success',
        output: {
          user: {
            consents: newAlpacaWFHData
          }
        },
        xCorrelationId: sessionContext.xCorrelationId
      })
    } else {
      console.log('Direct Redirect from Continue')
      redirect.push(redirectUri)
    }
  }

  return {
    handleConsentContinueClick,
    setRequiredPrivacy,
    setAnalytics,
    setAdvertising,
    setPersonalizedSuggestions,
    setDataTransfer,
    noContent: !alpacaData,
    alpacaData: alpacaData,
    craftData,
    country,
    loading,
    acceptAllButtonLoading,
    declineAllButtonLoading,
    continueButtonLoading,
    modalCraftData,
    requiredPrivacy,
    analytics,
    advertising,
    dataTransfer,
    personalizedSuggestions,
    defaultConsentContent,
    appAnalyticsContent,
    advertisingContent,
    personalizedSuggestionsContent,
    dataTransferContent,
    isAnalyticsOn,
    isAlreadyConsented
  }
}
