import React from 'react'
import { useAdminSettingsHook } from '../hooks'
import GOComponent from './GOComponent'

const GOContainer = ({
  authProvider,
  navigation,
  sessionContext,
  nextStage,
  language,
  country
}) => {
  const goSettingsProps = useAdminSettingsHook(
    authProvider,
    navigation,
    sessionContext,
    nextStage,
    language,
    country
  )
  return <GOComponent {...goSettingsProps} />
}

export default GOContainer
