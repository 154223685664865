import React from 'react'
import { usePostOobeSettingsHook } from './hooks'
import OobePreferences from './OobePreferences'

const PostOobeContainer = ({ authProvider, localization }) => {
  const { language = 'en', country = 'US' } = localization
  const oobeSettingsProps = usePostOobeSettingsHook(authProvider, language, country)
  return <OobePreferences {...oobeSettingsProps} />
}

export default PostOobeContainer
