import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  ReTargetContentContainer,
  Title,
  Body,
  ToggleLayout,
  ToggleLabelText,
  ToggleLabelHeader
} from '../../styles/adminPrivacySettingsStyles'
import Toggle from '@veneer/core/dist/scripts/toggle'
import List from '@veneer/core/dist/scripts/list'
import ListItem from '@veneer/core/dist/scripts/list_item'
import IconJobs from '@veneer/core/dist/scripts/icons/icon_jobs'
import IconBarChart from '@veneer/core/dist/scripts/icons/icon_bar_chart'
import IconMegaphone from '@veneer/core/dist/scripts/icons/icon_megaphone'

const GOPrivacySettings = (props) => {
  const {
    analytics = false,
    advertising = false,
    setAnalytics,
    setAdvertising,
    appAnalyticsContent = '',
    advertisingContent = '',
    defaultConsentContent = '',
    modalCraftData
  } = props
  const {
    entry: { smart_suite_body, smart_suite_consent_header_text, smart_suite_heading_text }
  } = modalCraftData
  const goPrivacySubHeadingText = smart_suite_body[0]?.body?.body_text
  const { ffSmbVeneerFonts } = useFlags()
  const requiredBasicSmbTitle =
    smart_suite_consent_header_text[0]?.consent_headers?.consent_header_text
  const appAnalyticsTitle = smart_suite_consent_header_text[1]?.consent_headers?.consent_header_text
  const advertisingTitle = smart_suite_consent_header_text[2]?.consent_headers?.consent_header_text

  const PrivacyConsents = () => {
    return (
      <List>
        <ListItem
          leadingArea={<IconJobs />}
          centerArea={<ToggleLabelHeader>{requiredBasicSmbTitle}</ToggleLabelHeader>}
        />
        <ListItem
          leadingArea={
            <ToggleLabelText dangerouslySetInnerHTML={{ __html: defaultConsentContent }} />
          }
        />
        <ListItem
          leadingArea={<IconBarChart />}
          centerArea={<ToggleLabelHeader>{appAnalyticsTitle}</ToggleLabelHeader>}
          trailingArea={
            <Toggle
              id="app-analytics-toggle"
              defaultOn={analytics}
              onChange={(e) => {
                setAnalytics(e)
              }}
            />
          }
        />
        <ListItem
          leadingArea={
            <ToggleLabelText dangerouslySetInnerHTML={{ __html: appAnalyticsContent }} />
          }
        />
        <ListItem
          leadingArea={<IconMegaphone />}
          centerArea={<ToggleLabelHeader>{advertisingTitle}</ToggleLabelHeader>}
          trailingArea={
            <Toggle
              id="advertising-toggle"
              defaultOn={advertising}
              onChange={(e) => {
                setAdvertising(e)
              }}
            />
          }
        />
        <ListItem
          leadingArea={<ToggleLabelText dangerouslySetInnerHTML={{ __html: advertisingContent }} />}
        />
      </List>
    )
  }

  return (
    <div id="settings-container">
      <Title id="privacy-title-text" fontFlag={ffSmbVeneerFonts}>
        {smart_suite_heading_text}
      </Title>
      <ReTargetContentContainer id="content-container">
        <Body
          fontFlag={ffSmbVeneerFonts}
          id="settings-container-body"
          dangerouslySetInnerHTML={{ __html: goPrivacySubHeadingText }}
        />
        <ToggleLayout id="settings-container-toggle-layout" fontFlag={ffSmbVeneerFonts}>
          <PrivacyConsents />
        </ToggleLayout>
      </ReTargetContentContainer>
    </div>
  )
}

export default GOPrivacySettings
