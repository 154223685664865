import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  max-height: 100vh;
  min-height: auto;
  overflow-y: hidden;
  box-sizing: border-box;
  max-width: 90vw;
  padding-top: 2vw;
  padding-bottom: 0;
  margin: 0 5vw;
  font-family: 'HP Simplified', Arial, sans-serif;

  @media screen and (min-width: 48rem) {
    overflow: hidden;
  }
  > * {
    margin: 0;
    padding: 16px 0;
    text-align: center;
    :first-child {
      padding-top: 0;
    }
    @media screen and (min-width: 25.875rem) {
      padding: 0px 0px 2px 0px;
    }
  }
  @media (min-width: 53.75rem) {
    font-size: 0.875rem;
    max-width: 64rem;
  }
  }
  @media all and (-ms-high-contrast: active) and (max-width: 1100px),
    all and (-ms-high-contrast: none) and (max-width: 1100px) {
    padding-top: 5vw;
    margin-top: 0;
  }
`

export const BodyContainer = styled.div`
  overflow: auto;
  padding: 0 5vw;
  text-align: 'center';
  @media all and (-ms-high-contrast: active) and (max-width: 1100px),
    all and (-ms-high-contrast: none) and (max-width: 1100px) {
    overflow-y: auto;
    max-height: 75vh;
  }
`

export const MainBody = styled.div`
  &&&& {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }
    padding: 0;
    width: 100%;
    ${(props) =>
      props.fontFlag
        ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
        : `font-family: 'HP Simplified', Arial, sans-serif`};
    font-size: 1rem;
    line-height: 1.25;
    color: #4e4e4e;
    p,
    > span > p {
      margin: 1rem 0;
      > a {
        color: #027aae;
        :hover {
          color: #006691;
        }
        :active {
          color: #004f74;
        }
      }
    }
  }
`

export const Title = styled.h4`
  && {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }
    color: #313131;
    ${(props) =>
      props.fontFlag
        ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
        : `font-family: 'HP Simplified', Arial, sans-serif`};
    line-height: 1.3;
    font-size: 1.25rem;
    font-weight: bold;
  }
`
export const BodyList = styled.span`
  > ul {
    list-style: disc;
    text-align: left;
    margin-left: 2rem;
  }
`

export const Graphic = styled.img`
  @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
    margin-left: 50%;
    transform: translate(-50%);
  }
  height: 6.25rem;
  width: auto;
`
