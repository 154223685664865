import React from 'react'
import App from '../../App'
import PostOobeContainer from '../../SmartWelcome/PostOobeContainer'
import LaunchDarkly from '../../SmartWelcome/LaunchDarkly'
import { BrowserRouter as Router } from 'react-router-dom'

const PortalConsents = (props) => {
  if (props.type === 'SMBPrivacyManageOptions') {
    return <PostOobeContainer {...props} />
  }

  return (
    <Router>
      <LaunchDarkly clientId={props.ldClientId}>
        <App {...props} />
      </LaunchDarkly>
    </Router>
  )
}

export default PortalConsents
