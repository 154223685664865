import { prop, path } from 'ramda'

export const stylesIf = (propName, trueValue = '', falseValue = '') => (props) =>
  prop(propName, props) ? trueValue : falseValue

export const breakpoints = {
  small: '413px', // 0 > small mobile < 413px
  mobile: '767px', // 414px >= mobile < 767px
  tablet: '1023px', // 767px >= tablet < 1023px
  desktop: '1200px' // 1023px >= desktop
}

//value in pixels
export const maxMobileWidth = 414

export const theme = {
  fonts: {
    familyLight: '"hp-simplified-light", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
    family: '"hp-simplified", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.25rem',
    xlg: '1.5rem',
    xxlg: '2rem',
    xxxlg: '3rem',
    godzilla: '3.75rem'
  },
  colors: {
    hpBlue: '#0096D5',
    black: '#000000',
    dark: '#212121',
    nearDark: '#313131',
    darkGrey: '#404040',
    white: '#FFFFFF',
    lightBackgroundGrey: '#F4F4F4',
    extralightBackgroundGrey: '#F6F6F6'
  },
  breakpoints
}

export const paths = {
  colorsDarkGrey: path(['theme', 'colors', 'darkGrey']),
  colorsWhite: path(['theme', 'colors', 'white']),
  colorsNearDark: path(['theme', 'colors', 'nearDark']),
  colorsDark: path(['theme', 'colors', 'dark']),
  colorsBlack: path(['theme', 'colors', 'black']),
  colorsHpBlue: path(['theme', 'colors', 'hpBlue']),
  colorsLightBackgroundGrey: path(['theme', 'colors', 'lightBackgroundGrey']),
  colorsExtraLightBackgroundGrey: path(['theme', 'colors', 'extralightBackgroundGrey']),
  fontsSm: path(['theme', 'fonts', 'sm']),
  fontsMd: path(['theme', 'fonts', 'md']),
  fontsLg: path(['theme', 'fonts', 'lg']),
  fontsXLg: path(['theme', 'fonts', 'xlg']),
  fontsXXLg: path(['theme', 'fonts', 'xxlg']),
  fontsXXXLg: path(['theme', 'fonts', 'xxxlg']),
  fontsGodzilla: path(['theme', 'fonts', 'godzilla']),
  fontsFamily: path(['theme', 'fonts', 'family']),
  fontsFamilyLight: path(['theme', 'fonts', 'familyLight']),
  breakSmall: path(['theme', 'breakpoints', 'small']),
  breakMobile: path(['theme', 'breakpoints', 'mobile']),
  breakTablet: path(['theme', 'breakpoints', 'tablet']),
  breakDesktop: path(['theme', 'breakpoints', 'desktop'])
}

export default {
  stylesIf
}
