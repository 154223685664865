/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { useWFHConsentHook } from '../hooks'
import WFHComponent from './WFHComponent'

import { ONBOARDING_WFH } from '../../utils/commons'

const WFHContainer = ({
  authProvider,
  navigation,
  sessionContext,
  nextStage,
  language,
  country
}) => {
  const deviceUuid = sessionContext?.device?.uuid
  if (!deviceUuid) {
    console.log('Portal Consents >> Error: Device ID Missing')
    return <h3>Device Id missing. Please provide device id.</h3>
  }
  const adminSettingsProps = useWFHConsentHook(
    authProvider,
    navigation,
    sessionContext,
    nextStage,
    language,
    country,
    ONBOARDING_WFH,
    deviceUuid
  )
  return <WFHComponent {...adminSettingsProps} />
}

export default WFHContainer
