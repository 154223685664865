import React, { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  ReTargetContentContainer,
  Title,
  Body,
  ToggleLayout,
  ToggleLabelText,
  ToggleLabelHeader
} from '../../styles/adminPrivacySettingsStyles'
import Toggle from '@veneer/core/dist/scripts/toggle'
import List from '@veneer/core/dist/scripts/list'
import ListItem from '@veneer/core/dist/scripts/list_item'
import IconJobs from '@veneer/core/dist/scripts/icons/icon_jobs'
import IconShare from '@veneer/core/dist/scripts/icons/icon_share'
import IconBarChart from '@veneer/core/dist/scripts/icons/icon_bar_chart'
import IconMegaphone from '@veneer/core/dist/scripts/icons/icon_megaphone'
import IconChat from '@veneer/core/dist/scripts/icons/icon_chat'
import { determineLocale } from '../../utils/determineLocale'
import { CHINA } from '../../utils/commons'
import {
  publishEvent,
  ManageConsentPrinterRequiredExpandedOD,
  ManageConsentPrinterDataTransferExpandedOD,
  ManageConsentPrinterAnalyticsExpandedOD,
  ManageConsentPrinterAdvertisingExpandedOD,
  ManageConsentPrinterPersonalizationExpandedOD,
  ManageConsentDescriptionLearnMoreClickedOD,
  ManageConsentAdvertisingLearnMoreClickedOD
} from '../../utils/analyticsOD'
const isOD = true

const ODPrivacySettings = (props) => {
  const {
    analytics = false,
    advertising = false,
    dataTransfer = false,
    personalizedSuggestions = false,
    setAnalytics,
    setAdvertising,
    setDataTransfer,
    setPersonalizedSuggestions,
    appAnalyticsContent = '',
    advertisingContent = '',
    dataTransferContent = '',
    personalizedSuggestionsContent = '',
    defaultConsentContent = '',
    modalCraftData
  } = props
  const {
    entry: { smart_suite_body, smart_suite_consent_header_text, smart_suite_heading_text }
  } = modalCraftData
  const { country } = determineLocale()
  const smbAdminPrivacySubHeadingText = smart_suite_body[0]?.body?.body_text
  const { ffSmbVeneerFonts } = useFlags()
  const isPipl = country === CHINA

  useEffect(() => {
    publishEvent(ManageConsentPrinterRequiredExpandedOD)
    isPipl && publishEvent(ManageConsentPrinterDataTransferExpandedOD)
    publishEvent(ManageConsentPrinterAnalyticsExpandedOD)
    publishEvent(ManageConsentPrinterAdvertisingExpandedOD)
    publishEvent(ManageConsentPrinterPersonalizationExpandedOD)
  }, [])

  let requiredBasicPrinterTitle,
    appAnalyticsTitle,
    advertisingTitle,
    personalizedTitle,
    dataTransferTitle

  if (!isPipl) {
    requiredBasicPrinterTitle =
      smart_suite_consent_header_text[0]?.consent_headers.consent_header_text
    appAnalyticsTitle = smart_suite_consent_header_text[1]?.consent_headers.consent_header_text
    advertisingTitle = smart_suite_consent_header_text[2]?.consent_headers.consent_header_text
    personalizedTitle = smart_suite_consent_header_text[3]?.consent_headers.consent_header_text
  } else {
    requiredBasicPrinterTitle =
      smart_suite_consent_header_text[0]?.consent_headers.consent_header_text
    dataTransferTitle = smart_suite_consent_header_text[1]?.consent_headers.consent_header_text
    appAnalyticsTitle = smart_suite_consent_header_text[2]?.consent_headers.consent_header_text
    advertisingTitle = smart_suite_consent_header_text[3]?.consent_headers.consent_header_text
    personalizedTitle = smart_suite_consent_header_text[4]?.consent_headers.consent_header_text
  }

  const PrivacyConsents = () => {
    return (
      <List>
        <ListItem
          leadingArea={<IconJobs />}
          centerArea={<ToggleLabelHeader>{requiredBasicPrinterTitle}</ToggleLabelHeader>}
        />
        <ListItem
          leadingArea={
            <ToggleLabelText dangerouslySetInnerHTML={{ __html: defaultConsentContent }} />
          }
        />
        {isPipl && (
          <ListItem
            leadingArea={<IconShare />}
            centerArea={<ToggleLabelHeader>{dataTransferTitle}</ToggleLabelHeader>}
            trailingArea={
              <Toggle
                id="data-transfer-toggle"
                defaultOn={dataTransfer}
                onChange={(e) => {
                  setDataTransfer(e)
                  setAnalytics(false)
                  setAdvertising(false)
                  setPersonalizedSuggestions(false)
                }}
              />
            }
          />
        )}
        {isPipl && (
          <ListItem
            leadingArea={
              <ToggleLabelText dangerouslySetInnerHTML={{ __html: dataTransferContent }} />
            }
          />
        )}
        <ListItem
          leadingArea={<IconBarChart />}
          centerArea={<ToggleLabelHeader>{appAnalyticsTitle}</ToggleLabelHeader>}
          trailingArea={
            <Toggle
              id="app-analytics-toggle"
              disabled={isPipl && !dataTransfer}
              defaultOn={analytics}
              onChange={(e) => {
                setAnalytics(e)
              }}
            />
          }
        />
        <ListItem
          leadingArea={
            <ToggleLabelText dangerouslySetInnerHTML={{ __html: appAnalyticsContent }} />
          }
        />
        <ListItem
          leadingArea={<IconMegaphone />}
          centerArea={<ToggleLabelHeader>{advertisingTitle}</ToggleLabelHeader>}
          trailingArea={
            <Toggle
              id="advertising-toggle"
              disabled={isPipl && !dataTransfer}
              defaultOn={advertising}
              onChange={(e) => {
                setAdvertising(e)
              }}
            />
          }
        />
        <ListItem
          leadingArea={
            <ToggleLabelText
              onClick={fireAnalyticsEvent}
              dangerouslySetInnerHTML={{ __html: advertisingContent }}
            />
          }
        />
        <ListItem
          leadingArea={<IconChat />}
          centerArea={<ToggleLabelHeader>{personalizedTitle}</ToggleLabelHeader>}
          trailingArea={
            <Toggle
              id="personalized-suggestions-toggle"
              disabled={isPipl && !dataTransfer}
              defaultOn={personalizedSuggestions}
              onChange={(e) => {
                setPersonalizedSuggestions(e)
              }}
            />
          }
        />
        <ListItem
          leadingArea={
            <ToggleLabelText dangerouslySetInnerHTML={{ __html: personalizedSuggestionsContent }} />
          }
        />
      </List>
    )
  }

  const fireAnalyticsEvent = (e) => {
    const closestAnchor = e.target.closest('a')
    if (!closestAnchor || !e.currentTarget.contains(closestAnchor)) return
    if (closestAnchor.href.indexOf('printer-data-collection-notice') > -1) {
      publishEvent(ManageConsentDescriptionLearnMoreClickedOD)
    } else if (closestAnchor.href.indexOf('data-sharing-notice') > -1) {
      publishEvent(ManageConsentAdvertisingLearnMoreClickedOD)
    } else {
      // Write any default click event
    }
  }

  return (
    <div id="settings-container">
      <Title id="privacy-title-text" fontFlag={ffSmbVeneerFonts}>
        {smart_suite_heading_text}
      </Title>
      <ReTargetContentContainer id="content-container">
        <Body
          fontFlag={ffSmbVeneerFonts}
          id="settings-container-body"
          onClick={fireAnalyticsEvent}
          dangerouslySetInnerHTML={{ __html: smbAdminPrivacySubHeadingText }}
          od={isOD}
        />
        <ToggleLayout id="settings-container-toggle-layout" od={isOD} fontFlag={ffSmbVeneerFonts}>
          <PrivacyConsents />
        </ToggleLayout>
      </ReTargetContentContainer>
    </div>
  )
}

export default ODPrivacySettings
