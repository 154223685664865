import { path } from 'ramda'

const activity = 'Privacy-v01'
const screenPath = '/ReactPrivacyConsents/'
const screenPathManage = '/ReactPrivacyConsents/PrinterConsent/'
const screenName = 'PrinterConsent'
const screenNameManage = 'PrinterManageOptions'
const screenMode = 'DeclineOptionalShown'
const screenMode1 = 'Wfh'
const version = '1.5.0'

export const publishEvent = (event) => {
  const publishCdmEvents = path(['Shell', 'v1', 'analytics', 'publishCdmEvents'], window)

  if (!publishCdmEvents) {
    console.error('Shell.v1.analytics.publishCdmEvents is not defined')
    return
  }

  publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType: 'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      eventDetail: event,
      version: '1.4.0'
    }
  ])
}

// Events

export const ConsentScreenDisplayedOD = {
  activity,
  screenPath,
  screenName,
  screenMode,
  action: 'ScreenDisplayed',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ConsentControlDisplayedOD = {
  activity,
  screenPath,
  screenName,
  screenMode,
  action: 'ControlDisplayed',
  controlName: 'AcceptAll',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ConsentScreenDisplayedWFH = {
  activity,
  screenPath,
  screenName,
  screenMode: screenMode1,
  action: 'ScreenDisplayed',
  actionAuxParams: 'source=adminPortal&flow=wfh',
  version
}

export const ConsentControlDisplayedWFH = {
  activity,
  screenPath,
  screenName,
  screenMode: screenMode1,
  action: 'ControlDisplayed',
  controlName: 'Continue',
  actionAuxParams: 'source=adminPortal&flow=wfh',
  version
}

export const ConsentAcceptAllButtonClickedOD = {
  activity,
  screenPath,
  screenName,
  screenMode,
  action: 'ControlButtonClicked',
  controlName: 'AcceptAll',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ConsentDeclineAllButtonClickedOD = {
  activity,
  screenPath,
  screenName,
  screenMode,
  action: 'ControlButtonClicked',
  controlName: 'DeclineAll',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ConsentManageOptionsButtonClickedOD = {
  activity,
  screenPath,
  screenName,
  screenMode,
  action: 'ControlButtonClicked',
  controlName: 'ManageOptions',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ConsentContinueButtonClickedWFH = {
  activity,
  screenPath,
  screenName,
  screenMode: screenMode1,
  action: 'ControlButtonClicked',
  controlName: 'Continue',
  actionAuxParams: 'source=adminPortal&flow=wfh',
  version
}

export const ConsentDescriptionLearnMoreClickedOD = {
  activity,
  screenPath,
  screenName,
  screenMode,
  action: 'ControlHyperLinkClicked',
  controlName: 'LearnMore',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ConsentDescriptionPrivacyClickedOD = {
  activity,
  screenPath,
  screenName,
  screenMode,
  action: 'ControlHyperLinkClicked',
  controlName: 'Privacy',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentScreenDisplayedOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ScreenDisplayed',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentControlDisplayedOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlDisplayed',
  controlName: 'Continue',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentContinueButtonClickedOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlButtonClicked',
  controlName: 'Continue',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentBackButtonClickedOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlButtonClicked',
  controlName: 'Back',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentPrinterRequiredExpandedOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlAccordianExpanded',
  controlName: 'PrinterRequired',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentPrinterDataTransferExpandedOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlAccordianExpanded',
  controlName: 'DataTransfer',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentPrinterAnalyticsExpandedOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlAccordianExpanded',
  controlName: 'PrinterAnalytics',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentPrinterAdvertisingExpandedOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlAccordianExpanded',
  controlName: 'PrinterAdvertising',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentPrinterPersonalizationExpandedOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlAccordianExpanded',
  controlName: 'PrinterPersonalization',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentDataTransferToggleEnabledOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleEnabled',
  controlName: 'DataTransfer',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentDataTransferToggleDisabledOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleDisabled',
  controlName: 'DataTransfer',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentAnalyticsToggleEnabledOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleEnabled',
  controlName: 'PrinterAnalytics',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentAnalyticsToggleDisabledOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleDisabled',
  controlName: 'PrinterAnalytics',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentAdvertisingToggleEnabledOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleEnabled',
  controlName: 'PrinterAdvertising',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentAdvertisingToggleDisabledOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleDisabled',
  controlName: 'PrinterAdvertising',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentPersonalizedSuggToggleEnabledOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleEnabled',
  controlName: 'PrinterPersonalization',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentPersonalizedSuggToggleDisabledOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleDisabled',
  controlName: 'PrinterPersonalization',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentDataTransferToggleEnabledWFH = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleEnabled',
  controlName: 'DataTransfer',
  actionAuxParams: 'source=adminPortal&flow=wfh',
  version
}

export const ManageConsentDataTransferToggleDisabledWFH = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleDisabled',
  controlName: 'DataTransfer',
  actionAuxParams: 'source=adminPortal&flow=wfh',
  version
}

export const ManageConsentAnalyticsToggleEnabledWFH = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleEnabled',
  controlName: 'PrinterAnalytics',
  actionAuxParams: 'source=adminPortal&flow=wfh',
  version
}

export const ManageConsentAnalyticsToggleDisabledWFH = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleDisabled',
  controlName: 'PrinterAnalytics',
  actionAuxParams: 'source=adminPortal&flow=wfh',
  version
}

export const ManageConsentAdvertisingToggleEnabledWFH = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleEnabled',
  controlName: 'PrinterAdvertising',
  actionAuxParams: 'source=adminPortal&flow=wfh',
  version
}

export const ManageConsentAdvertisingToggleDisabledWFH = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleDisabled',
  controlName: 'PrinterAdvertising',
  actionAuxParams: 'source=adminPortal&flow=wfh',
  version
}

export const ManageConsentPersonalizedSuggToggleEnabledWFH = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleEnabled',
  controlName: 'PrinterPersonalization',
  actionAuxParams: 'source=adminPortal&flow=wfh',
  version
}

export const ManageConsentPersonalizedSuggToggleDisabledWFH = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlToggleDisabled',
  controlName: 'PrinterPersonalization',
  actionAuxParams: 'source=adminPortal&flow=wfh',
  version
}

export const ManageConsentDescriptionLearnMoreClickedOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlHyperLinkClicked',
  controlName: 'LearnMore',
  controlDetail: 'ManageConsents',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}

export const ManageConsentAdvertisingLearnMoreClickedOD = {
  activity,
  screenPath: screenPathManage,
  screenName: screenNameManage,
  action: 'ControlHyperLinkClicked',
  controlName: 'LearnMore',
  controlDetail: 'Advertising',
  actionAuxParams: 'source=adminPortal&flow=deviceOnboarding',
  version
}
