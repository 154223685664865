import React from 'react'
import { useECPConsentHook } from '../hooks'
import ECPComponent from './ECPComponent'

const ECPContainer = ({
  authProvider,
  navigation,
  sessionContext,
  nextStage,
  language,
  country
}) => {
  const adminSettingsProps = useECPConsentHook(
    authProvider,
    navigation,
    sessionContext,
    nextStage,
    language,
    country,
    'ecp'
  )

  return <ECPComponent {...adminSettingsProps} />
}

export default ECPContainer
