import React from 'react'
import {
  Container,
  BodyContainer,
  StyledButton,
  Title,
  ButtonContainer,
  MainBody,
  Body
} from '../../styles/adminStyles'
import { useAdminSettingsHook } from '../hooks'
import { useLocation, useHistory } from 'react-router'

const ECPComponent = (props) => {
  const {
    navigation,
    nextStage,
    sessionContext,
    authProvider,
    language,
    country,
    craftData,
    handleClick
  } = props

  const { search } = useLocation()
  const history = useHistory()

  const queryParams = new URLSearchParams(search)
  const redirectUri = queryParams.get('redirectUri')
  const redirect = navigation || history

  return (
    <Container>
      <BodyContainer id="content-container">
        <Title id="title-text">{craftData?.entry.smart_suite_heading_text}</Title>
        <MainBody id="hpsmart-content">
          <span
            dangerouslySetInnerHTML={{
              __html: craftData?.entry.smart_suite_body[0].body.body_text
            }}
          />
        </MainBody>
        <Body id="hpsmart-content">
          <span
            dangerouslySetInnerHTML={{
              __html: craftData?.entry.smart_suite_body[0].body.secondary_body_text
            }}
          />
        </Body>
        <ButtonContainer>
          <StyledButton onClick={handleClick} appearance={'primary'} id="continue-button">
            Continue
          </StyledButton>
        </ButtonContainer>
      </BodyContainer>
    </Container>
  )
}

export default ECPComponent
