/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios'
import { splitLocaleIntoCountryAndLanguage } from '../utils/determineLocale'
import {
  COUNTRIES,
  LANGUAGES,
  DEFAULT_COUNTRY,
  DEFAULT_LANGUAGE,
  LANGUAGES_SET,
  SIMPLIFIED_CHINESE_COUNTRIES,
  SUPPORTED_LANGUAGES
} from '../constants/i18n'
import { PROD_URL, STAGE_URL } from '../constants/baseApiUrl'

import { getAppConfig } from './appConfig'

const base = '/api'

const craftBase = (baseUrl) =>
  baseUrl.indexOf('api') > -1 ? `${axios.defaults.baseURL.replace('api', 'www')}/api` : '/api'

const contentStackCommon = 'content/v3/content_types/consents/entries/'

export async function consents(requestObj, version = 2) {
  const { url, method = 'GET', data = '', headers, ...options } = requestObj
  const apiBase = version === 2 ? base : '/in-app-api'
  const consentBase = `${apiBase}/${version}`
  const fullUrl = `${consentBase}/consents${url}`

  try {
    const response = await axios({
      url: fullUrl,
      method,
      withCredentials: true,
      data,
      headers,
      ...options
    })
    return response
  } catch (e) {
    e.method = method
    e.url = fullUrl
    throw e
  }
}

export const getWelcomeData = async (id, purposeId, language, country) => {
  const url = `?deviceId=${id}&purposeId=${purposeId}&language=${language}&country=${country}`
  return await consents({ url })
}

export const postConsents = (id, purposeId, language, resourceId, country) => {
  const url = `?deviceId=${id}&purposeId=${purposeId}&language=${language}&country=${country}&resourceId=${resourceId}&state=OptedIn`
  return consents({ url, method: 'PATCH' })
}

export const getPrinterConsentsData = async (id, purposeIdList, language) => {
  return await consents({
    url: '',
    method: 'POST',
    data: {
      language,
      purposeIdList,
      deviceId: id
    }
  })
}

export const getPrinterData = async (language, purposeIdList) => {
  const array = JSON.stringify(purposeIdList)
  const url = `/verbiage?language=${language}&purposeId=${array}`
  return consents({
    url: url,
    method: 'GET',
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json'
    }
  })
}

export const getAdminData = async (language, accessToken, purposeIdList) => {
  const url = `/smb `
  return consents({
    url,
    method: 'POST',
    headers: {
      Accept: '*/*',
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      language,
      purposeIdList
    }
  })
}

export const postECPData = async (language, accessToken, purposeIdList) => {
  const url = `/ecp`
  return consents({
    url,
    method: 'POST',
    headers: {
      Accept: '*/*',
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      language,
      purposeIdList
    }
  })
}

export const postODData = async (language, purposeIdList, deviceId) => {
  const url = ``
  return consents({
    url,
    method: 'POST',
    headers: {
      Accept: '*/*'
      // Authorization: `Bearer ${accessToken}`
    },
    data: {
      language,
      purposeIdList,
      deviceId
    }
  })
}

export const postODConsents = (language, country, data, deviceId) => {
  const url = `?language=${language}&country=${country.toLowerCase()}&platform=windows&deviceId=${deviceId}`
  return consents({
    url,
    method: 'PATCH',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const postAdminConsents = (language, data, accessToken) => {
  const url = `/smb/?language=${language}`
  return consents({
    url,
    method: 'PATCH',
    data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export const getUserPrinterConsents = async (id, language, country) => {
  const url = `?deviceId=${id}&language=${language}&country=${country}`
  return await consents({ url })
}

export const setUserPrinterConsents = (id, language, country, platform, data) => {
  const url = `?deviceId=${id}&language=${language}&country=${country}&platform=${platform}`
  return consents({ url, method: 'PATCH', data })
}

export const getDefaultConsents = async (version = 1, country, userOrPrinter) => {
  const url = `/${userOrPrinter}?country=${country}&version=${version}`
  const response = await consents({ url }, version)
  if (response.status === 200 || response.status === '200') {
    return response.data
  }
  // return undefined as there is no default values for this country.
  return undefined
}

const ensureCraftLocale = (locale) => {
  const { language, country } = splitLocaleIntoCountryAndLanguage(locale)
  if (language === LANGUAGES.ZH) {
    if (SIMPLIFIED_CHINESE_COUNTRIES.has(country)) {
      return `${language}_${COUNTRIES.CN}`
    }
    if (country === COUNTRIES.HK) {
      return `${language}_${COUNTRIES.TW}`
    }
    return `${language}_${country}`
  }
  const defaultLanguage = LANGUAGES_SET.has(language) ? language : DEFAULT_LANGUAGE
  const defaultCountry = SUPPORTED_LANGUAGES[defaultLanguage][0]
  return `${defaultLanguage}_${defaultCountry}`
}

const ensureContentStackLocale = (locale) => {
  const { language, country } = splitLocaleIntoCountryAndLanguage(locale)
  if (language === LANGUAGES.ZH) {
    if (SIMPLIFIED_CHINESE_COUNTRIES.has(country)) {
      return `${language}-${COUNTRIES.CN}`
    }
    if (country === COUNTRIES.HK) {
      return `${language}-${COUNTRIES.TW}`
    }
    return `${language}-${country}`
  }
  const defaultLanguage = LANGUAGES_SET.has(language) ? language : DEFAULT_LANGUAGE
  const defaultCountry = SUPPORTED_LANGUAGES[defaultLanguage][0]
  return `${defaultLanguage}-${defaultCountry}`
}

const getChinaDomain = (url, locale, env) => {
  switch (env) {
    case 'production':
      return PROD_URL
    case 'stage':
      return STAGE_URL
    default:
      return url
  }
}

// If api fails for China Network for https://api.hpsmart{stack}.com
// then will call same api again with https://www.hp-smart{stack}.cn

export const apiCall = async (
  axiosBaseChinaURL,
  contentStackCommon,
  contentStackLocale,
  current_env,
  contentStackRequestHeaders,
  entryID
) => {
  return axios
    .get(
      `${contentStackCommon}${entryID}?locale=${contentStackLocale}&environment=${current_env}`,
      { headers: contentStackRequestHeaders }
    )
    .then((response) => response)
    .catch((error) =>
      axios
        .get(
          `${axiosBaseChinaURL}/${contentStackCommon}${entryID}?locale=${contentStackLocale}&environment=${current_env}`,
          { headers: contentStackRequestHeaders }
        )
        .then((response) => response)
        .catch((error) => {
          return {
            entry: {
              smart_suite_heading_text: 'Unable to fetch data'
            }
          }
        })
    )
}

export const getCraftData = (resource, locale, type) => {
  const craftLocale = ensureCraftLocale(locale)
  const contentStackLocale = ensureContentStackLocale(locale)
  if (type === 'printer-consents') {
    return getAppConfig().then((configData) => {
      const {
        data: { content_stack_access_token, api_key, current_env }
      } = configData

      const contentStackRequestHeaders = {
        api_key: api_key,
        access_token: content_stack_access_token
      }
      const axiosBaseChinaURL = getChinaDomain(
        axios.defaults.baseURL,
        contentStackLocale,
        current_env
      )
      return apiCall(
        axiosBaseChinaURL,
        contentStackCommon,
        contentStackLocale,
        current_env,
        contentStackRequestHeaders,
        'blt7931a5abccb6c51c'
      ).then((response) => response)
    })
  } else if (type === 'printer-consents-update') {
    return getAppConfig().then((configData) => {
      const {
        data: { content_stack_access_token, api_key, current_env }
      } = configData

      const contentStackRequestHeaders = {
        api_key: api_key,
        access_token: content_stack_access_token
      }
      const axiosBaseChinaURL = getChinaDomain(
        axios.defaults.baseURL,
        contentStackLocale,
        current_env
      )
      return apiCall(
        axiosBaseChinaURL,
        contentStackCommon,
        contentStackLocale,
        current_env,
        contentStackRequestHeaders,
        'blt6c33092d365998ee'
      ).then((response) => response)
    })
  } else if (type === 'admin-printer-consents') {
    return getAppConfig().then((configData) => {
      const {
        data: { content_stack_access_token, api_key, current_env }
      } = configData

      const contentStackRequestHeaders = {
        api_key: api_key,
        access_token: content_stack_access_token
      }
      const axiosBaseChinaURL = getChinaDomain(
        axios.defaults.baseURL,
        contentStackLocale,
        current_env
      )
      return apiCall(
        axiosBaseChinaURL,
        contentStackCommon,
        contentStackLocale,
        current_env,
        contentStackRequestHeaders,
        'blt15f329f5fd7e8787'
      ).then((response) => response)
    })
  } else if (type === 'admin-printer-consents-update') {
    return getAppConfig().then((configData) => {
      const {
        data: { content_stack_access_token, api_key, current_env }
      } = configData

      const contentStackRequestHeaders = {
        api_key: api_key,
        access_token: content_stack_access_token
      }
      const axiosBaseChinaURL = getChinaDomain(
        axios.defaults.baseURL,
        contentStackLocale,
        current_env
      )
      return apiCall(
        axiosBaseChinaURL,
        contentStackCommon,
        contentStackLocale,
        current_env,
        contentStackRequestHeaders,
        'blt438bfa484cd49c01'
      ).then((response) => response)
    })
  } else if (type === 'go-printer-consents') {
    return getAppConfig().then((configData) => {
      const {
        data: { content_stack_access_token, api_key, current_env }
      } = configData

      const contentStackRequestHeaders = {
        api_key: api_key,
        access_token: content_stack_access_token
      }
      const axiosBaseChinaURL = getChinaDomain(
        axios.defaults.baseURL,
        contentStackLocale,
        current_env
      )
      return apiCall(
        axiosBaseChinaURL,
        contentStackCommon,
        contentStackLocale,
        current_env,
        contentStackRequestHeaders,
        'blt873218770e68f6ed'
      ).then((response) => response)
    })
  } else if (type === 'go-printer-consents-update') {
    return getAppConfig().then((configData) => {
      const {
        data: { content_stack_access_token, api_key, current_env }
      } = configData

      const contentStackRequestHeaders = {
        api_key: api_key,
        access_token: content_stack_access_token
      }
      const axiosBaseChinaURL = getChinaDomain(
        axios.defaults.baseURL,
        contentStackLocale,
        current_env
      )
      return apiCall(
        axiosBaseChinaURL,
        contentStackCommon,
        contentStackLocale,
        current_env,
        contentStackRequestHeaders,
        'blt10f4c809db1ce146'
      ).then((response) => response)
    })
  } else if (type === 'ecp') {
    return getAppConfig().then((configData) => {
      const {
        data: { content_stack_access_token, api_key, current_env }
      } = configData

      const contentStackRequestHeaders = {
        api_key: api_key,
        access_token: content_stack_access_token
      }
      return axios.get(
        `${contentStackCommon}blt74235a805b1f4c5a?locale=${contentStackLocale}&environment=${current_env}`,
        { headers: contentStackRequestHeaders }
      )
    })
  } else if (type === 'od') {
    return getAppConfig().then((configData) => {
      const {
        data: { content_stack_access_token, api_key, current_env }
      } = configData

      const contentStackRequestHeaders = {
        api_key: api_key,
        access_token: content_stack_access_token
      }
      return axios.get(
        `${contentStackCommon}blt7931a5abccb6c51c?locale=${contentStackLocale}&environment=${current_env}`,
        { headers: contentStackRequestHeaders }
      )
    })
  } else if (type === 'od-update') {
    return getAppConfig().then((configData) => {
      const {
        data: { content_stack_access_token, api_key, current_env }
      } = configData

      const contentStackRequestHeaders = {
        api_key: api_key,
        access_token: content_stack_access_token
      }
      return axios.get(
        `${contentStackCommon}blt6c33092d365998ee?locale=${contentStackLocale}&environment=${current_env}`,
        { headers: contentStackRequestHeaders }
      )
    })
  } else if (type === 'WFH') {
    return getAppConfig().then((configData) => {
      const {
        data: { content_stack_access_token, api_key, current_env }
      } = configData

      const contentStackRequestHeaders = {
        api_key: api_key,
        access_token: content_stack_access_token
      }
      return axios.get(
        `${contentStackCommon}blt5d62edd4aa025d8a?locale=${contentStackLocale}&environment=${current_env}`,
        { headers: contentStackRequestHeaders }
      )
    })
  }

  return getAppConfig().then((configData) => {
    const {
      data: { content_stack_access_token, api_key, current_env }
    } = configData

    const contentStackRequestHeaders = {
      api_key: api_key,
      access_token: content_stack_access_token
    }
    return axios.get(
      `${contentStackCommon}blt438bfa484cd49c01?locale=${contentStackLocale}&environment=${current_env}`,
      { headers: contentStackRequestHeaders }
    )
  })
}
