import React, { useState } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { StyledButton, Body, FooterButton, ButtonContainer } from '../../styles/adminStyles'
import { ConsentsButtonContainer } from '../../styles/adminPrivacySettingsStyles'
import ODPrivacySettings from './ODPrivacySettings'
import {
  publishEvent,
  ConsentManageOptionsButtonClickedOD,
  ManageConsentScreenDisplayedOD,
  ManageConsentControlDisplayedOD,
  ManageConsentBackButtonClickedOD,
  ConsentScreenDisplayedOD,
  ConsentDescriptionLearnMoreClickedOD,
  ConsentDescriptionPrivacyClickedOD
} from '../../utils/analyticsOD'

import { Container, BodyContainer, Title, MainBody, BodyList, Graphic } from '../../styles/odStyles'

const ODComponent = (props) => {
  const {
    handleConsentAcceptAllClick,
    handleConsentDeclineAllClick,
    handleManageOptionsContinueClick,
    dataValueStore = {},
    alpacaData = [],
    craftData = {},
    modalCraftData = {},
    country,
    loading,
    acceptAllButtonLoading,
    declineAllButtonLoading,
    continueButtonLoading,
    isAlreadyConsented,
    isAnalyticsOn
  } = props
  const [show, setShow] = useState(false)
  const { ffSmbVeneerFonts } = useFlags()

  if (alpacaData) {
    console.log(Object.values(alpacaData)[0].verbiageValue, craftData)
  }

  console.log(`Onboarding Center Component > alpacaData: ${alpacaData}, craftData: ${craftData}`)
  console.log('Onboarding Center Component > valueStore: ', dataValueStore)

  if (alpacaData && craftData && !dataValueStore) {
    const {
      entry: {
        smart_suite_image,
        smart_suite_heading_text,
        smart_suite_body,
        accept_button_text,
        decline_button_text,
        manage_button_text
      }
    } = craftData
    const smbAdminBodyText1 = smart_suite_body[0].body.body_text
    const smbAdminBodyText2 = smart_suite_body[0].body.secondary_body_text
    const smbAdminBodyText3 = smart_suite_body[0].body.supplimental_text
    const { url: smbAdminGrapchicUrl } = smart_suite_image

    const fireAnalyticsEvent = (e) => {
      const closestAnchor = e.target.closest('a')
      if (!closestAnchor || !e.currentTarget.contains(closestAnchor)) return
      if (closestAnchor.href.indexOf('printer-data-collection-notice') > -1) {
        publishEvent(ConsentDescriptionLearnMoreClickedOD)
      } else if (closestAnchor.href.indexOf('privacy') > -1) {
        publishEvent(ConsentDescriptionPrivacyClickedOD)
      } else {
        // Write any default click event
      }
    }

    const getWelcomeContainer = () => {
      return (
        <Container>
          {!show && (
            <BodyContainer id="content-container">
              <Graphic id="graphic" src={smbAdminGrapchicUrl} alt="Printer and tablet line art" />
              <Title id="title-text" fontFlag={ffSmbVeneerFonts}>
                {smart_suite_heading_text}
              </Title>
              <MainBody id="hpsmart-content" fontFlag={ffSmbVeneerFonts}>
                <div>
                  <span
                    onClick={fireAnalyticsEvent}
                    dangerouslySetInnerHTML={{ __html: smbAdminBodyText1 }}
                  />
                  {country === 'us' ? (
                    <BodyList dangerouslySetInnerHTML={{ __html: smbAdminBodyText2 }} />
                  ) : (
                    <BodyList dangerouslySetInnerHTML={{ __html: smbAdminBodyText3 }} />
                  )}
                </div>
              </MainBody>

              <ButtonContainer fontFlag={ffSmbVeneerFonts}>
                {alpacaData && (
                  <StyledButton
                    onClick={handleConsentAcceptAllClick}
                    loading={acceptAllButtonLoading}
                    appearance="primary"
                    id="consent-button"
                  >
                    {accept_button_text}
                  </StyledButton>
                )}
                {alpacaData && (
                  <StyledButton
                    onClick={handleConsentDeclineAllClick}
                    loading={declineAllButtonLoading}
                    appearance="primary"
                    id="consent-decline-all-button"
                  >
                    {decline_button_text}
                  </StyledButton>
                )}
                {alpacaData && (
                  <StyledButton
                    onClick={() => {
                      setShow(true)
                      publishEvent(ConsentManageOptionsButtonClickedOD)
                      publishEvent(ManageConsentScreenDisplayedOD)
                      publishEvent(ManageConsentControlDisplayedOD)
                    }}
                    appearance="secondary"
                    id="consent-manage-options-button"
                  >
                    {manage_button_text}
                  </StyledButton>
                )}
              </ButtonContainer>
            </BodyContainer>
          )}
          {show && (
            // <Modal
            //   id="regular-modal"
            //   onClose={() => setShow(false)}
            //   show={show}
            //   footer={

            //   }
            // >
            <BodyContainer id="content-container">
              <ODPrivacySettings {...props} />
              <ConsentsButtonContainer id="consents-button-container" fontFlag={ffSmbVeneerFonts}>
                <FooterButton
                  onClick={handleManageOptionsContinueClick}
                  id="smb-consent-continue-button"
                  loading={continueButtonLoading}
                >
                  {modalCraftData.entry.continue_button_text}
                </FooterButton>
                <FooterButton
                  onClick={() => {
                    setShow(false)
                    publishEvent(ManageConsentBackButtonClickedOD)
                    publishEvent(ConsentScreenDisplayedOD)
                  }}
                  appearance="secondary"
                  id="smb-consent-back-button"
                >
                  {modalCraftData.entry.back_button_text}
                </FooterButton>
              </ConsentsButtonContainer>
            </BodyContainer>
            // </Modal>
          )}
        </Container>
      )
    }

    return getWelcomeContainer()
  }

  return <ProgressIndicator appearance="linear" />
}

export default ODComponent
